import { useContext, useEffect, useState } from 'react';
import { UserSettingsContext } from '@notidar/content';
import { createMeasurementConversionContextState } from '@notidar/content/src/fields/measurement/MeasurementConversionContext';
import { AllFields } from '@notidar/content/src/utils';
import { FieldType, MeasurementField } from '@notidar/api';

export const useMeasurementConversionContextState = (fields?: AllFields[]) => {
  const userSettings = useContext(UserSettingsContext);

  const [measurementConversionContextState, setMeasurementConversionContextState] = useState((userSettings?.measurements && fields && fields.length > 0)
    ? createMeasurementConversionContextState(userSettings.measurements, fields.filter(x => x.type === FieldType.Measurement) as MeasurementField[])
    : {});

  useEffect(
    () => {
      if (userSettings?.measurements && fields && fields.length > 0) {
        setMeasurementConversionContextState(
          createMeasurementConversionContextState(
            userSettings.measurements,
            fields.filter(x => x.type === FieldType.Measurement) as MeasurementField[]
          )
        );
      }
    },
    [userSettings, fields]
  );

  return measurementConversionContextState;
}