/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type ActionsPostSection = PostSection & {
  actionItems: ActionsPostSectionItem[]
}

export interface ActionsPostSectionItem {
  /** @minLength 1 */
  actionField: string
  /** @minLength 1 */
  displayString: string
}

export interface AreaSettings {
  system?: MeasurementSystem
}

export type AuthorPostSection = PostSection & {
  /** @minLength 1 */
  authorField: string
  linkField?: string | null
  dateTimeField?: string | null
}

export type BoolField = Field

export interface BoolPayload {
  value?: boolean | null
}

export type BoolValue = Value & {
  boolPayload?: BoolPayload
}

export interface Channel {
  /** @format uuid */
  channelId: string
  status: ChannelStatus
  visibilityStatus: ChannelVisibilityStatus
  /** @minLength 1 */
  name: string
  /** @minLength 1 */
  displayName: string
  /** @minLength 1 */
  title: string
  description?: string | null
  imageUrl?: string | null
  /** @format int32 */
  latestIndex?: number
  /** @format int32 */
  minActiveIndex?: number
  /** @format int32 */
  fieldsVersion: number
  fields: (
    | BoolField
    | DateTimeField
    | EnumField
    | EnumListField
    | FileListField
    | ImageFileListField
    | GeographyField
    | LinkField
    | LinkListField
    | LocationField
    | MarkdownField
    | MeasurementField
    | MoneyField
    | NumberField
    | StringField
    | StringListField
  )[]
  filters: (EnumFilter | MeasurementFilter | MoneyFilter | NumberFilter | TextFilter)[]
  postSections: (
    | ActionsPostSection
    | AuthorPostSection
    | GalleryPostSection
    | MapPostSection
    | MarkdownPostSection
    | PropertiesPostSection
    | TagsPostSection
    | TitlePostSection
  )[]
  previewSections: (GalleryPreviewSection | PropertiesPreviewSection | TitlePreviewSection)[]
}

export enum ChannelStatus {
  Ready = 'Ready',
  Updating = 'Updating',
  Creating = 'Creating',
  Deleting = 'Deleting',
}

export enum ChannelVisibilityStatus {
  Public = 'Public',
  Private = 'Private',
}

export interface CheckChannelNameRequest {
  /** @minLength 1 */
  name: string
}

export interface Connection {
  /** @format uuid */
  connectionId: string
  status: ConnectionStatus
  /** @minLength 1 */
  channelName: string
  /** @format date-time */
  createdDate: string
  /** @format date-time */
  synchronizationDate: string
}

export enum ConnectionStatus {
  Initialization = 'Initialization',
  Available = 'Available',
  Blocked = 'Blocked',
}

export interface CreateChannelRequest {
  /**
   * @minLength 5
   * @maxLength 32
   * @pattern ^[a-z0-9]{1}[a-z0-9_.]{3,}[a-z0-9]{1}$
   */
  name: string
  /**
   * @minLength 4
   * @maxLength 120
   */
  title: string
  /** @maxLength 420 */
  description: string
}

export interface CreateChannelResponse {
  channel: Channel
}

export interface CreateFileResponse {
  file?: File
}

export interface CreatePostRequest {
  /** @minLength 1 */
  channelIdentifier: string
  externalUniqueId?: string | null
  /** @format date-time */
  expireDate?: string | null
  values: Record<
    string,
    | BoolValue
    | DateTimeValue
    | EnumValue
    | EnumListValue
    | FileListValue
    | ImageFileListValue
    | GeographyValue
    | LinkValue
    | LinkListValue
    | LocationValue
    | MarkdownValue
    | MeasurementValue
    | MoneyValue
    | NumberValue
    | StringValue
    | StringListValue
  >
}

export interface CreatePostResponse {
  post?: Post
}

export interface CreateRssConnectionRequest {
  /** @minLength 1 */
  link: string
}

export interface CreateRssConnectionResponse {
  connection: Connection
}

export interface CreateSubscriptionRequest {
  /**
   * @format uuid
   * @minLength 1
   */
  channelId: string
  filters?: (EnumFilter | MeasurementFilter | MoneyFilter | NumberFilter | TextFilter)[]
}

export interface CreateSubscriptionResponse {
  subscription?: Subscription
}

export interface CreateSupportTicketRequest {
  type?: SupportTicketType
  /** @minLength 1 */
  subject: string
}

export interface CreateSupportTicketResponse {
  supportTicket: SupportTicket
}

export enum CurrencyCode {
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
  PLN = 'PLN',
  CAD = 'CAD',
  NOK = 'NOK',
}

export type DateTimeField = Field & {
  /** @minLength 1 */
  minimalValidMask: string
}

export interface DateTimePayload {
  /** @format date-time */
  value?: string | null
  mask?: string | null
}

export type DateTimeValue = Value & {
  dateTimePayload?: DateTimePayload
}

export type DeleteChannelResponse = object

export type DeletePostResponse = object

export type DeleteSubscriptionResponse = object

export type EnumField = Field & {
  possibleValues: EnumOption[]
}

export type EnumFilter = Filter & {
  enumIds?: string[] | null
}

export type EnumListField = Field & {
  possibleValues: EnumOption[]
}

export interface EnumListPayload {
  enumIds?: string[] | null
}

export type EnumListValue = Value & {
  enumListPayload?: EnumListPayload
}

export interface EnumOption {
  /** @minLength 1 */
  enumId: string
  /** @minLength 1 */
  displayString: string
}

export interface EnumPayload {
  enumId?: string | null
}

export type EnumValue = Value & {
  enumPayload?: EnumPayload
}

export interface ErrorCodeProblemDetails {
  type?: string | null
  title?: string | null
  /** @format int32 */
  status?: number | null
  detail?: string | null
  instance?: string | null
  errorCode?: string | null
  [key: string]: any
}

export interface Field {
  type: FieldType
  /** @minLength 1 */
  name: string
  displayName?: string | null
  indexed?: boolean
  searchable?: boolean
}

export enum FieldType {
  Unknown = 'Unknown',
  String = 'String',
  Markdown = 'Markdown',
  Link = 'Link',
  Bool = 'Bool',
  Number = 'Number',
  Money = 'Money',
  DateTime = 'DateTime',
  Enum = 'Enum',
  EnumList = 'EnumList',
  StringList = 'StringList',
  LinkList = 'LinkList',
  ImageFileList = 'ImageFileList',
  FileList = 'FileList',
  Measurement = 'Measurement',
  Geography = 'Geography',
  Location = 'Location',
}

export interface File {
  /** @format uuid */
  fileId?: string
}

export type FileListField = Field

export interface FileListPayload {
  fileIds?: string[] | null
}

export type FileListValue = Value & {
  fileListPayload?: FileListPayload
}

export enum FileType {
  Unknown = 'Unknown',
  JpegFile = 'JpegFile',
  GifFile = 'GifFile',
  PngFile = 'PngFile',
}

export interface Filter {
  type: FilterType
  operationType: FilterOperationType
  /** @minLength 1 */
  field: string
  fieldType: FieldType
}

export enum FilterOperationType {
  Unknown = 'Unknown',
  Any = 'Any',
  AllIn = 'AllIn',
  AnyIn = 'AnyIn',
  AllNotIn = 'AllNotIn',
  AnyNotIn = 'AnyNotIn',
  Gte = 'Gte',
  Lte = 'Lte',
  Between = 'Between',
  TextSearch = 'TextSearch',
}

export enum FilterType {
  Unknown = 'Unknown',
  Text = 'Text',
  Enum = 'Enum',
  Number = 'Number',
  Money = 'Money',
  Measurement = 'Measurement',
}

export type GalleryPostSection = PostSection & {
  /** @minLength 1 */
  mediaField: string
}

export type GalleryPreviewSection = PreviewSection & {
  /** @minLength 1 */
  mediaField: string
}

export interface GeoJsonGeometry {
  type: GeometryType
}

export type GeoJsonPoint = GeoJsonGeometry & {
  coordinates: number[]
}

export type GeoJsonPolygon = GeoJsonGeometry & {
  coordinates: number[][][]
}

export type GeographyField = Field

export interface GeographyPayload {
  geometry: GeoJsonPoint | GeoJsonPolygon
}

export type GeographyValue = Value & {
  geographyPayload?: GeographyPayload
}

export enum GeometryType {
  Point = 'Point',
  Polygon = 'Polygon',
}

export interface GetChannelPostResponse {
  post?: Post
}

export interface GetChannelPostsRequest {
  pageToken?: string | null
}

export interface GetChannelPostsResponse {
  posts: Post[]
  pageToken?: string | null
  /** @format int32 */
  fieldsVersion: number
}

export interface GetChannelResponse {
  channel: Channel
}

export interface GetChannelsRequest {
  /** @maxLength 100 */
  searchTerm?: string | null
  pageToken?: string | null
}

export interface GetChannelsResponse {
  channels: Channel[]
  pageToken?: string | null
}

export interface GetConnectionResponse {
  connection: Connection
}

export interface GetCurrencyConversionResponse {
  /** @format date-time */
  updatedDate?: string
  rates?: {
    /** @format double */
    USD?: number
    /** @format double */
    EUR?: number
    /** @format double */
    RUB?: number
    /** @format double */
    PLN?: number
    /** @format double */
    CAD?: number
    /** @format double */
    NOK?: number
  }
}

export interface GetSubscriptionResponse {
  subscription?: Subscription
}

export interface GetSubscriptionsResponse {
  subscriptions: Subscription[]
}

export interface GetSupportTicketsResponse {
  supportTickets: SupportTicket[]
}

export interface GetUserChannelsResponse {
  channels: Channel[]
}

export interface GetUserResponse {
  user: User
}

export type HttpValidationProblemDetails = ProblemDetails & {
  errors?: Record<string, string[]>
  [key: string]: any
}

export enum IconType {
  RadioBullet = 'RadioBullet',
  Phone = 'Phone',
  Mail = 'Mail',
  Location = 'Location',
  Link = 'Link',
}

export type ImageFileListField = Field

export interface ImageFileListPayload {
  fileIds?: string[] | null
}

export type ImageFileListValue = Value & {
  imageFileListPayload?: ImageFileListPayload
}

export enum LanguageCode {
  EN = 'EN',
  RU = 'RU',
  PL = 'PL',
}

export interface LengthSettings {
  system?: MeasurementSystem
}

export type LinkField = Field

export type LinkListField = Field

export interface LinkListPayload {
  links?: string[] | null
}

export type LinkListValue = Value & {
  linkListPayload?: LinkListPayload
}

export interface LinkPayload {
  link?: string | null
}

export type LinkValue = Value & {
  linkPayload?: LinkPayload
}

export type LocationField = Field

export interface LocationPayload {
  geometry: GeoJsonPoint | GeoJsonPolygon
  locationName?: string | null
}

export type LocationValue = Value & {
  locationPayload?: LocationPayload
}

export type MapPostSection = PostSection & {
  /** @minLength 1 */
  geographyField: string
}

export type MarkdownField = Field

export interface MarkdownPayload {
  value?: string | null
}

export type MarkdownPostSection = PostSection & {
  /** @minLength 1 */
  markdownField: string
}

export type MarkdownValue = Value & {
  markdownPayload?: MarkdownPayload
}

export type MeasurementField = Field & {
  unit: Unit
}

export type MeasurementFilter = Filter & {
  /** @format int64 */
  lowerLimit?: number | null
  /** @format int64 */
  upperLimit?: number | null
}

export interface MeasurementPayload {
  /** @format int64 */
  value?: number | null
}

export interface MeasurementSettings {
  system?: MeasurementSystem
  temperature?: TemperatureSettings
  length?: LengthSettings
  area?: AreaSettings
}

export enum MeasurementSystem {
  Custom = 'Custom',
  Metric = 'Metric',
  Imperial = 'Imperial',
}

export type MeasurementValue = Value & {
  measurementPayload?: MeasurementPayload
}

export type MoneyField = Field & {
  currency: CurrencyCode
}

export type MoneyFilter = Filter & {
  /** @format int64 */
  lowerLimit?: number | null
  /** @format int64 */
  upperLimit?: number | null
}

export interface MoneyPayload {
  /** @format int64 */
  value?: number | null
}

export type MoneyValue = Value & {
  moneyPayload?: MoneyPayload
}

export enum NotificationLevel {
  None = 'None',
  HighImportance = 'HighImportance',
  All = 'All',
}

export type NumberField = Field & {
  /** @format int32 */
  exponent: number
  suffix?: string | null
}

export type NumberFilter = Filter & {
  /** @format int64 */
  lowerLimit?: number | null
  /** @format int64 */
  upperLimit?: number | null
}

export interface NumberPayload {
  /** @format int64 */
  value?: number | null
}

export type NumberValue = Value & {
  numberPayload?: NumberPayload
}

export interface Post {
  /** @format uuid */
  channelId: string
  /** @format uuid */
  postId: string
  values: Record<
    string,
    | BoolValue
    | DateTimeValue
    | EnumValue
    | EnumListValue
    | FileListValue
    | ImageFileListValue
    | GeographyValue
    | LinkValue
    | LinkListValue
    | LocationValue
    | MarkdownValue
    | MeasurementValue
    | MoneyValue
    | NumberValue
    | StringValue
    | StringListValue
  >
  /** @format date-time */
  createdDate?: string
  /** @format int32 */
  index?: number
}

export interface PostSection {
  type: PostSectionType
}

export enum PostSectionType {
  Title = 'Title',
  Author = 'Author',
  Tags = 'Tags',
  Markdown = 'Markdown',
  Actions = 'Actions',
  Gallery = 'Gallery',
  Properties = 'Properties',
  Map = 'Map',
}

export interface PreviewSection {
  type: PreviewSectionType
}

export enum PreviewSectionType {
  Title = 'Title',
  Gallery = 'Gallery',
  Properties = 'Properties',
}

export interface ProblemDetails {
  type?: string | null
  title?: string | null
  /** @format int32 */
  status?: number | null
  detail?: string | null
  instance?: string | null
  [key: string]: any
}

export type PropertiesPostSection = PostSection & {
  items: PropertiesPostSectionItem[]
  titleString?: string | null
  displayType: PropertiesPostSectionType
}

export interface PropertiesPostSectionItem {
  /** @minLength 1 */
  propertyField: string
  icon: IconType
}

export enum PropertiesPostSectionType {
  Names = 'Names',
  Icons = 'Icons',
}

export type PropertiesPreviewSection = PreviewSection & {
  items: PropertiesPreviewSectionItem[]
}

export interface PropertiesPreviewSectionItem {
  /** @minLength 1 */
  propertyField: string
  icon?: IconType
}

export enum PublisherAccountType {
  Basic = 'Basic',
  Demo = 'Demo',
  Premium = 'Premium',
  Enterprise = 'Enterprise',
}

export enum ReaderAccountType {
  Basic = 'Basic',
  Demo = 'Demo',
  Premium = 'Premium',
  Enterprise = 'Enterprise',
}

export interface SearchPostResponse {
  posts: Post[]
  pageToken?: string | null
  /** @format int32 */
  fieldsVersion: number
}

export interface SearchPostsRequest {
  filters?: (EnumFilter | MeasurementFilter | MoneyFilter | NumberFilter | TextFilter)[]
  pageToken?: string | null
}

export enum SortOrder {
  Descending = 'Descending',
  Ascending = 'Ascending',
}

export type StringField = Field

export type StringListField = Field

export interface StringListPayload {
  values?: string[] | null
}

export type StringListValue = Value & {
  stringListPayload?: StringListPayload
}

export interface StringPayload {
  value?: string | null
}

export type StringValue = Value & {
  stringPayload?: StringPayload
}

export interface Subscription {
  /** @format uuid */
  subscriptionId: string
  /** @format uuid */
  channelId: string
  notificationLevel: NotificationLevel
  /** @format int32 */
  channelIndex?: number
  title?: string
  /** @minLength 1 */
  displayName: string
  filters: (EnumFilter | MeasurementFilter | MoneyFilter | NumberFilter | TextFilter)[]
}

export interface SupportTicket {
  /** @format uuid */
  supportTicketId: string
  /** @format uuid */
  userId: string
  /** @format date-time */
  createdDate: string
  /** @format date-time */
  closedDate: string
  type: SupportTicketType
  status: SupportTicketStatus
  /** @minLength 1 */
  subject: string
}

export enum SupportTicketStatus {
  Open = 'Open',
  Closed = 'Closed',
}

export enum SupportTicketType {
  General = 'General',
  Feedback = 'Feedback',
  Technical = 'Technical',
  Billing = 'Billing',
  DemoRequest = 'DemoRequest',
  Other = 'Other',
}

export type TagsPostSection = PostSection & {
  /** @minLength 1 */
  tagsField: string
}

export interface TemperatureSettings {
  system?: MeasurementSystem
  temperatureUnit?: Unit
}

export type TextFilter = Filter & {
  query?: string | null
}

export enum Theme {
  System = 'System',
  Dark = 'Dark',
  Light = 'Light',
}

export type TitlePostSection = PostSection & {
  /** @minLength 1 */
  titleField: string
  linkField?: string | null
  subtitleField?: string | null
}

export type TitlePreviewSection = PreviewSection & {
  /** @minLength 1 */
  titleField: string
  linkField?: string | null
}

export enum Unit {
  TemperatureCelsius = 'Temperature_Celsius',
  TemperatureFahrenheit = 'Temperature_Fahrenheit',
  TemperatureKelvin = 'Temperature_Kelvin',
  LengthMeter = 'Length_Meter',
  LengthMeterAndCentimeter = 'Length_MeterAndCentimeter',
  LengthCentimeter = 'Length_Centimeter',
  LengthFoot = 'Length_Foot',
  LengthFootAndInch = 'Length_FootAndInch',
  LengthInch = 'Length_Inch',
  AreaSquareMeter = 'Area_SquareMeter',
  AreaSquareFoot = 'Area_SquareFoot',
}

export interface UpdateChannelFieldsRequest {
  /** @minItems 1 */
  fields: (
    | BoolField
    | DateTimeField
    | EnumField
    | EnumListField
    | FileListField
    | ImageFileListField
    | GeographyField
    | LinkField
    | LinkListField
    | LocationField
    | MarkdownField
    | MeasurementField
    | MoneyField
    | NumberField
    | StringField
    | StringListField
  )[]
}

export interface UpdateChannelFieldsResponse {
  channel?: Channel
}

export interface UpdateChannelFiltersRequest {
  /** @minItems 1 */
  filters: (EnumFilter | MeasurementFilter | MoneyFilter | NumberFilter | TextFilter)[]
}

export interface UpdateChannelFiltersResponse {
  channel?: Channel
}

export interface UpdateChannelMetadataRequest {
  /**
   * @minLength 4
   * @maxLength 120
   */
  title: string
  /** @maxLength 420 */
  description: string
}

export interface UpdateChannelMetadataResponse {
  channel?: Channel
}

export interface UpdateChannelPostSectionsRequest {
  /** @minItems 1 */
  postSections: (
    | ActionsPostSection
    | AuthorPostSection
    | GalleryPostSection
    | MapPostSection
    | MarkdownPostSection
    | PropertiesPostSection
    | TagsPostSection
    | TitlePostSection
  )[]
}

export interface UpdateChannelPostSectionsResponse {
  channel?: Channel
}

export interface UpdateChannelPreviewSectionsRequest {
  /** @minItems 1 */
  previewSections: (GalleryPreviewSection | PropertiesPreviewSection | TitlePreviewSection)[]
}

export interface UpdateChannelPreviewSectionsResponse {
  channel?: Channel
}

export interface UpdateChannelVisibilityRequest {
  visibilityStatus: ChannelVisibilityStatus
}

export interface UpdateChannelVisibilityResponse {
  channel?: Channel
}

export interface UpdatePostRequest {
  /** @format date-time */
  expireDate?: string | null
  values: Record<
    string,
    | BoolValue
    | DateTimeValue
    | EnumValue
    | EnumListValue
    | FileListValue
    | ImageFileListValue
    | GeographyValue
    | LinkValue
    | LinkListValue
    | LocationValue
    | MarkdownValue
    | MeasurementValue
    | MoneyValue
    | NumberValue
    | StringValue
    | StringListValue
  >
}

export interface UpdateSubscriptionFilterValuesRequest {
  filters?: (EnumFilter | MeasurementFilter | MoneyFilter | NumberFilter | TextFilter)[]
}

export type UpdateSubscriptionFilterValuesResponse = object

export interface UpdateSubscriptionIndexRequest {
  /**
   * @format int32
   * @min 0
   */
  index?: number
}

export type UpdateSubscriptionIndexResponse = object

export interface UpdateSubscriptionNotificationLevelRequest {
  notificationLevel?: NotificationLevel
}

export type UpdateSubscriptionNotificationLevelResponse = object

export interface UpdateUserCurrencyRequest {
  currencyCode?: CurrencyCode
}

export interface UpdateUserLanguageRequest {
  language: LanguageCode
}

export interface UpdateUserMeasurementSettingsRequest {
  measurementSettings?: MeasurementSettings
}

export interface UpdateUserShowManagementUIRequest {
  showManagementUI: boolean
}

export interface UpdateUserThemeRequest {
  theme: Theme
}

export interface User {
  /** @format uuid */
  userId: string
  status: UserStatus
  /** @minLength 1 */
  email: string
  readerAccountType: ReaderAccountType
  publisherAccountType: PublisherAccountType
  settings: UserSettings
  toggles: UserToggles
}

export interface UserSettings {
  theme: Theme
  language: LanguageCode
  currencyCode?: CurrencyCode
  measurements?: MeasurementSettings
}

export enum UserStatus {
  Ready = 'Ready',
  Updating = 'Updating',
}

export interface UserToggles {
  showManagementUI: boolean
}

export interface Value {
  type: FieldType
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ''
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter(key => 'undefined' !== typeof query[key])
    return keys
      .map(key => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async response => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then(data => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch(e => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title Notidar API
 * @version 1.0-alpha
 *
 * Notidar services api.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Channel
     * @name GetChannelAsync
     * @request GET:/api/v1-alpha/channels/{channelIdentifier}
     * @response `200` `GetChannelResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     */
    getChannelAsync: (channelIdentifier: string, params: RequestParams = {}) =>
      this.request<GetChannelResponse, ErrorCodeProblemDetails>({
        path: `/api/v1-alpha/channels/${channelIdentifier}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name DeleteChannelAsync
     * @request DELETE:/api/v1-alpha/channels/{channelIdentifier}
     * @secure
     * @response `200` `DeleteChannelResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    deleteChannelAsync: (channelIdentifier: string, params: RequestParams = {}) =>
      this.request<DeleteChannelResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/channels/${channelIdentifier}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name GetChannelsAsync
     * @request GET:/api/v1-alpha/channels
     * @response `200` `GetChannelsResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    getChannelsAsync: (
      query?: {
        /** @maxLength 100 */
        SearchTerm?: string | null
        PageToken?: string | null
      },
      params: RequestParams = {},
    ) =>
      this.request<GetChannelsResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/channels`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name CreateChannelAsync
     * @request POST:/api/v1-alpha/channels
     * @secure
     * @response `200` `CreateChannelResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    createChannelAsync: (data: CreateChannelRequest, params: RequestParams = {}) =>
      this.request<CreateChannelResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/channels`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name GetUserChannelsAsync
     * @request GET:/api/v1-alpha/users/me/channels
     * @secure
     * @response `200` `GetUserChannelsResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    getUserChannelsAsync: (params: RequestParams = {}) =>
      this.request<GetUserChannelsResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/users/me/channels`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name UpdateChannelPostSectionsAsync
     * @request PUT:/api/v1-alpha/channels/{channelIdentifier}/sections/post
     * @secure
     * @response `200` `UpdateChannelPostSectionsResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    updateChannelPostSectionsAsync: (
      channelIdentifier: string,
      data: UpdateChannelPostSectionsRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        UpdateChannelPostSectionsResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/channels/${channelIdentifier}/sections/post`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name UpdateChannelPreviewSectionsAsync
     * @request PUT:/api/v1-alpha/channels/{channelIdentifier}/sections/preview
     * @secure
     * @response `200` `UpdateChannelPreviewSectionsResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    updateChannelPreviewSectionsAsync: (
      channelIdentifier: string,
      data: UpdateChannelPreviewSectionsRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        UpdateChannelPreviewSectionsResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/channels/${channelIdentifier}/sections/preview`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name UpdateChannelFieldsAsync
     * @request PUT:/api/v1-alpha/channels/{channelIdentifier}/fields
     * @secure
     * @response `200` `UpdateChannelFieldsResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    updateChannelFieldsAsync: (
      channelIdentifier: string,
      data: UpdateChannelFieldsRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        UpdateChannelFieldsResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/channels/${channelIdentifier}/fields`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name UpdateChannelFiltersAsync
     * @request PUT:/api/v1-alpha/channels/{channelIdentifier}/filters
     * @secure
     * @response `200` `UpdateChannelFiltersResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    updateChannelFiltersAsync: (
      channelIdentifier: string,
      data: UpdateChannelFiltersRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        UpdateChannelFiltersResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/channels/${channelIdentifier}/filters`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name UpdateChannelMetadataAsync
     * @request PUT:/api/v1-alpha/channels/{channelIdentifier}/metadata
     * @secure
     * @response `200` `UpdateChannelMetadataResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    updateChannelMetadataAsync: (
      channelIdentifier: string,
      data: UpdateChannelMetadataRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        UpdateChannelMetadataResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/channels/${channelIdentifier}/metadata`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name CheckChannelNameAsync
     * @request POST:/api/v1-alpha/validation/channel/name
     * @secure
     * @response `200` `void` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    checkChannelNameAsync: (data: CheckChannelNameRequest, params: RequestParams = {}) =>
      this.request<void, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/validation/channel/name`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name UpdateChannelVisibilityAsync
     * @request PUT:/api/v1-alpha/channels/{channelIdentifier}/visibility
     * @secure
     * @response `200` `UpdateChannelVisibilityResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    updateChannelVisibilityAsync: (
      channelIdentifier: string,
      data: UpdateChannelVisibilityRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        UpdateChannelVisibilityResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/channels/${channelIdentifier}/visibility`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connection
     * @name CreateRssConnectionAsync
     * @request POST:/api/v1-alpha/connection/rss
     * @response `200` `CreateRssConnectionResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     */
    createRssConnectionAsync: (data: CreateRssConnectionRequest, params: RequestParams = {}) =>
      this.request<CreateRssConnectionResponse, ErrorCodeProblemDetails>({
        path: `/api/v1-alpha/connection/rss`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connection
     * @name GetConnectionAsync
     * @request GET:/api/v1-alpha/connection/{connectionId}
     * @response `200` `GetConnectionResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    getConnectionAsync: (connectionId: string, params: RequestParams = {}) =>
      this.request<GetConnectionResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/connection/${connectionId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetContentFields
     * @request GET:/api/v1-alpha/content/fields
     * @response `200` `(FieldType)[]` OK
     */
    getContentFields: (params: RequestParams = {}) =>
      this.request<FieldType[], any>({
        path: `/api/v1-alpha/content/fields`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetContentFilters
     * @request GET:/api/v1-alpha/content/filter
     * @response `200` `(FilterType)[]` OK
     */
    getContentFilters: (params: RequestParams = {}) =>
      this.request<FilterType[], any>({
        path: `/api/v1-alpha/content/filter`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetIconTypes
     * @request GET:/api/v1-alpha/content/icon
     * @response `200` `(IconType)[]` OK
     */
    getIconTypes: (params: RequestParams = {}) =>
      this.request<IconType[], any>({
        path: `/api/v1-alpha/content/icon`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetContentFilterOperations
     * @request GET:/api/v1-alpha/content/filter/operations
     * @response `200` `(FilterOperationType)[]` OK
     */
    getContentFilterOperations: (params: RequestParams = {}) =>
      this.request<FilterOperationType[], any>({
        path: `/api/v1-alpha/content/filter/operations`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetContentPostSections
     * @request GET:/api/v1-alpha/content/sections/post
     * @response `200` `(PostSectionType)[]` OK
     */
    getContentPostSections: (params: RequestParams = {}) =>
      this.request<PostSectionType[], any>({
        path: `/api/v1-alpha/content/sections/post`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetContentPreviewSections
     * @request GET:/api/v1-alpha/content/sections/preview
     * @response `200` `(PreviewSectionType)[]` OK
     */
    getContentPreviewSections: (params: RequestParams = {}) =>
      this.request<PreviewSectionType[], any>({
        path: `/api/v1-alpha/content/sections/preview`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetSortOrders
     * @request GET:/api/v1-alpha/content/sortOrder
     * @response `200` `(SortOrder)[]` OK
     */
    getSortOrders: (params: RequestParams = {}) =>
      this.request<SortOrder[], any>({
        path: `/api/v1-alpha/content/sortOrder`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetErrorCodes
     * @request GET:/api/v1-alpha/content/errorCode
     * @response `200` `(string)[]` OK
     */
    getErrorCodes: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api/v1-alpha/content/errorCode`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetLanguageCodes
     * @request GET:/api/v1-alpha/content/language
     * @response `200` `(LanguageCode)[]` OK
     */
    getLanguageCodes: (params: RequestParams = {}) =>
      this.request<LanguageCode[], any>({
        path: `/api/v1-alpha/content/language`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetCurrencyCodes
     * @request GET:/api/v1-alpha/content/currencies
     * @response `200` `(CurrencyCode)[]` OK
     */
    getCurrencyCodes: (params: RequestParams = {}) =>
      this.request<CurrencyCode[], any>({
        path: `/api/v1-alpha/content/currencies`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetMeasurementSystems
     * @request GET:/api/v1-alpha/content/measurements/systems
     * @response `200` `(MeasurementSystem)[]` OK
     */
    getMeasurementSystems: (params: RequestParams = {}) =>
      this.request<MeasurementSystem[], any>({
        path: `/api/v1-alpha/content/measurements/systems`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name GetUnits
     * @request GET:/api/v1-alpha/content/measurements/units
     * @response `200` `(Unit)[]` OK
     */
    getUnits: (params: RequestParams = {}) =>
      this.request<Unit[], any>({
        path: `/api/v1-alpha/content/measurements/units`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Currency
     * @name GetCurrencyRates
     * @request GET:/api/v1-alpha/content/currencies/{currencyCode}/rates
     * @response `200` `GetCurrencyConversionResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     */
    getCurrencyRates: (currencyCode: CurrencyCode, params: RequestParams = {}) =>
      this.request<GetCurrencyConversionResponse, ErrorCodeProblemDetails>({
        path: `/api/v1-alpha/content/currencies/${currencyCode}/rates`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name CreateFileAsync
     * @request POST:/api/v1-alpha/channels/{channelId}/files
     * @secure
     * @response `200` `CreateFileResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    createFileAsync: (
      channelId: string,
      data: {
        /** @format binary */
        File?: globalThis.File
        Type?: FileType
      },
      params: RequestParams = {},
    ) =>
      this.request<CreateFileResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/channels/${channelId}/files`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Post
     * @name GetChannelPostAsync
     * @request GET:/api/v1-alpha/posts/{postId}
     * @response `200` `GetChannelPostResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    getChannelPostAsync: (postId: string, params: RequestParams = {}) =>
      this.request<GetChannelPostResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/posts/${postId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Post
     * @name DeletePostAsync
     * @request DELETE:/api/v1-alpha/posts/{postId}
     * @secure
     * @response `200` `DeletePostResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    deletePostAsync: (postId: string, params: RequestParams = {}) =>
      this.request<DeletePostResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/posts/${postId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Post
     * @name SearchPostsAsync
     * @request POST:/api/v1-alpha/channels/{channelIdentifier}/posts
     * @response `200` `SearchPostResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    searchPostsAsync: (channelIdentifier: string, data: SearchPostsRequest, params: RequestParams = {}) =>
      this.request<SearchPostResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/channels/${channelIdentifier}/posts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Post
     * @name GetPostsAsync
     * @request GET:/api/v1-alpha/channels/{channelIdentifier}/posts
     * @response `200` `GetChannelPostsResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     */
    getPostsAsync: (
      channelIdentifier: string,
      query?: {
        PageToken?: string | null
      },
      params: RequestParams = {},
    ) =>
      this.request<GetChannelPostsResponse, ErrorCodeProblemDetails>({
        path: `/api/v1-alpha/channels/${channelIdentifier}/posts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Post
     * @name UpdatePostValuesAsync
     * @request POST:/api/v1-alpha/posts/{postId}/values
     * @secure
     * @response `200` `CreatePostResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    updatePostValuesAsync: (postId: string, data: UpdatePostRequest, params: RequestParams = {}) =>
      this.request<CreatePostResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/posts/${postId}/values`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Post
     * @name CreatePostAsync
     * @request POST:/api/v1-alpha/posts
     * @secure
     * @response `200` `CreatePostResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    createPostAsync: (data: CreatePostRequest, params: RequestParams = {}) =>
      this.request<CreatePostResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/posts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name GetSubscriptionAsync
     * @request GET:/api/v1-alpha/subscriptions/{subscriptionId}
     * @secure
     * @response `200` `GetSubscriptionResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    getSubscriptionAsync: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<GetSubscriptionResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/subscriptions/${subscriptionId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name GetSubscriptionsAsync
     * @request GET:/api/v1-alpha/subscriptions
     * @secure
     * @response `200` `GetSubscriptionsResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     */
    getSubscriptionsAsync: (params: RequestParams = {}) =>
      this.request<GetSubscriptionsResponse, ErrorCodeProblemDetails>({
        path: `/api/v1-alpha/subscriptions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name CreateSubscriptionAsync
     * @request POST:/api/v1-alpha/subscriptions
     * @secure
     * @response `200` `CreateSubscriptionResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     * @response `409` `(ProblemDetails | HttpValidationProblemDetails)` Conflict
     */
    createSubscriptionAsync: (data: CreateSubscriptionRequest, params: RequestParams = {}) =>
      this.request<
        CreateSubscriptionResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/subscriptions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name UpdateSubscriptionFiltersAsync
     * @request PUT:/api/v1-alpha/channels/{channelId}/subscriptions/{subscriptionId}/filterValues
     * @secure
     * @response `200` `UpdateSubscriptionFilterValuesResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     * @response `409` `(ProblemDetails | HttpValidationProblemDetails)` Conflict
     */
    updateSubscriptionFiltersAsync: (
      channelId: string,
      subscriptionId: string,
      data: UpdateSubscriptionFilterValuesRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        UpdateSubscriptionFilterValuesResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/channels/${channelId}/subscriptions/${subscriptionId}/filterValues`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name UpdateSubscriptionNotificationLevelAsync
     * @request PUT:/api/v1-alpha/channels/{channelId}/subscriptions/{subscriptionId}/notificationLevel
     * @secure
     * @response `200` `UpdateSubscriptionNotificationLevelResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    updateSubscriptionNotificationLevelAsync: (
      channelId: string,
      subscriptionId: string,
      data: UpdateSubscriptionNotificationLevelRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        UpdateSubscriptionNotificationLevelResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/channels/${channelId}/subscriptions/${subscriptionId}/notificationLevel`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name UpdateSubscriptionIndexAsync
     * @request PATCH:/api/v1-alpha/channel/{channelId}/subscription/{subscriptionId}/index
     * @secure
     * @response `200` `UpdateSubscriptionIndexResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    updateSubscriptionIndexAsync: (
      channelId: string,
      subscriptionId: string,
      data: UpdateSubscriptionIndexRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        UpdateSubscriptionIndexResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/channel/${channelId}/subscription/${subscriptionId}/index`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name DeleteSubscriptionAsync
     * @request DELETE:/api/v1-alpha/channel/{channelId}/subscriptions/{subscriptionId}
     * @secure
     * @response `200` `DeleteSubscriptionResponse` OK
     * @response `204` `void` No Content
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    deleteSubscriptionAsync: (channelId: string, subscriptionId: string, params: RequestParams = {}) =>
      this.request<
        DeleteSubscriptionResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/channel/${channelId}/subscriptions/${subscriptionId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupportTicket
     * @name CreateSupportTicketAsync
     * @request POST:/api/v1-alpha/users/me/supportTickets
     * @secure
     * @response `200` `CreateSupportTicketResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    createSupportTicketAsync: (data: CreateSupportTicketRequest, params: RequestParams = {}) =>
      this.request<
        CreateSupportTicketResponse,
        ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
      >({
        path: `/api/v1-alpha/users/me/supportTickets`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupportTicket
     * @name GetSupportTicketsAsync
     * @request GET:/api/v1-alpha/users/me/supportTickets
     * @secure
     * @response `200` `GetSupportTicketsResponse` OK
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    getSupportTicketsAsync: (params: RequestParams = {}) =>
      this.request<GetSupportTicketsResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/v1-alpha/users/me/supportTickets`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupportTicket
     * @name GetSupportTicketAsync
     * @request GET:/api/v1-alpha/users/me/supportTickets/{supportTicketId}
     * @secure
     * @response `200` `GetSupportTicketsResponse` OK
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     */
    getSupportTicketAsync: (supportTicketId: string, params: RequestParams = {}) =>
      this.request<GetSupportTicketsResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/v1-alpha/users/me/supportTickets/${supportTicketId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name GetUserAsync
     * @request GET:/api/v1-alpha/users/me
     * @secure
     * @response `200` `GetUserResponse` OK
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    getUserAsync: (params: RequestParams = {}) =>
      this.request<GetUserResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/v1-alpha/users/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UpdateUserLanguageAsync
     * @request POST:/api/v1-alpha/user/me/settings/language
     * @secure
     * @response `200` `GetUserResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    updateUserLanguageAsync: (data: UpdateUserLanguageRequest, params: RequestParams = {}) =>
      this.request<GetUserResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/user/me/settings/language`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UpdateUserThemeAsync
     * @request POST:/api/v1-alpha/user/me/settings/theme
     * @secure
     * @response `200` `GetUserResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    updateUserThemeAsync: (data: UpdateUserThemeRequest, params: RequestParams = {}) =>
      this.request<GetUserResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/user/me/settings/theme`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UpdateUserCurrencyAsync
     * @request POST:/api/v1-alpha/user/me/settings/currency
     * @secure
     * @response `200` `GetUserResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    updateUserCurrencyAsync: (data: UpdateUserCurrencyRequest, params: RequestParams = {}) =>
      this.request<GetUserResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/user/me/settings/currency`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UpdateUserMeasurementSettingsAsync
     * @request POST:/api/v1-alpha/user/me/settings/measurements
     * @secure
     * @response `200` `GetUserResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    updateUserMeasurementSettingsAsync: (data: UpdateUserMeasurementSettingsRequest, params: RequestParams = {}) =>
      this.request<GetUserResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/user/me/settings/measurements`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UpdateUserShowManagementUiAsync
     * @request POST:/api/v1-alpha/user/me/toggles/showManagementUI
     * @secure
     * @response `200` `GetUserResponse` OK
     * @response `400` `ErrorCodeProblemDetails` Bad Request
     * @response `401` `(ProblemDetails | HttpValidationProblemDetails)` Unauthorized
     * @response `404` `(ProblemDetails | HttpValidationProblemDetails)` Not Found
     */
    updateUserShowManagementUiAsync: (data: UpdateUserShowManagementUIRequest, params: RequestParams = {}) =>
      this.request<GetUserResponse, ErrorCodeProblemDetails | (ProblemDetails | HttpValidationProblemDetails)>({
        path: `/api/v1-alpha/user/me/toggles/showManagementUI`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
