import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiClient } from '../../api'
import type { RootState } from '../store'
import { OperationError, OperationStatus } from '../models'
import { ChannelId, SubscriptionId } from '@notidar/content'
import { Subscription, NotificationLevel, ErrorCode, getErrorMessageCode } from '@notidar/api'
import { AllFilters } from '@notidar/content/src/utils'

interface SubscriptionsState {
  subscriptions: Subscription[] | undefined
  status: OperationStatus
  subscriptionsError: OperationError

  actionId: number,
  actionSubscriptionId: string | undefined,
  actionStatus: OperationStatus,
  actionErrorCode: ErrorCode | null,
}

const initialState: SubscriptionsState = {
  status: 'idle',
  subscriptionsError: null,
  subscriptions: undefined,

  actionId: 1,
  actionSubscriptionId: undefined,
  actionStatus: 'idle',
  actionErrorCode: null,
}

export const getSubscriptions = createAsyncThunk(
  'subscriptions/getSubscriptions',
  async (_, thunkAPI) => {
    const response = await ApiClient.getSubscriptionsAsync({ signal: thunkAPI.signal })
    const subscriptions = response.data.subscriptions

    if (!subscriptions) {
      throw new Error(`Failed to get subscriptions`)
    }
    return subscriptions
  }
)

export const addSubscription = createAsyncThunk(
  'subscriptions/addSubscription',
  async (data: { actionId: number, channelId: ChannelId, filters?: AllFilters[] }, thunkAPI) => {
    try {
      const response = await ApiClient.createSubscriptionAsync(
        { channelId: data.channelId, filters: data.filters ?? [] },
        { signal: thunkAPI.signal }
      )
      return response.data.subscription
    } catch (e: unknown) {
      return thunkAPI.rejectWithValue(getErrorMessageCode(e));
    }
  }
)

export const updateSubscriptionFilters = createAsyncThunk(
  'subscriptions/updateSubscriptionFilters',
  async (data: { actionId: number, channelId: ChannelId, subscriptionId: SubscriptionId, filters: AllFilters[] }, thunkAPI) => {
    try {
      await ApiClient.updateSubscriptionFiltersAsync(
        data.channelId,
        data.subscriptionId,
        { filters: data.filters },
        { signal: thunkAPI.signal }
      )
    } catch (e: unknown) {
      return thunkAPI.rejectWithValue(getErrorMessageCode(e));
    }
  }
)

export const updateSubscriptionNotificationLevel = createAsyncThunk(
  'subscriptions/updateSubscriptionNotificationLevel',
  async (data: { actionId: number, channelId: ChannelId, subscriptionId: SubscriptionId, notificationLevel: NotificationLevel }, thunkAPI) => {
    try {
      await ApiClient.updateSubscriptionNotificationLevelAsync(
        data.channelId,
        data.subscriptionId,
        { notificationLevel: data.notificationLevel },
        { signal: thunkAPI.signal }
      );
    } catch (e: unknown) {
      return thunkAPI.rejectWithValue(getErrorMessageCode(e));
    }
  }
)

export const removeSubscription = createAsyncThunk(
  'subscriptions/removeSubscription',
  async (data: { actionId: number, subscriptionId: SubscriptionId, channelId: ChannelId }, thunkAPI) => {
    try {
      await ApiClient.deleteSubscriptionAsync(data.channelId, data.subscriptionId, { signal: thunkAPI.signal })
    } catch (e: unknown) {
      return thunkAPI.rejectWithValue(getErrorMessageCode(e));
    }
  }
)

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    resetSubscriptions: state => {
      state.subscriptions = undefined
      state.status = 'idle'
      state.subscriptionsError = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSubscriptions.rejected, (state, action) => {
        state.status = 'failed'
        state.subscriptionsError = 'Something failed'
        state.subscriptions = undefined
      })
      .addCase(getSubscriptions.pending, (state, action) => {
        state.status = 'loading'
        state.subscriptions = undefined
      })
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.subscriptions = action.payload
      })
      .addCase(addSubscription.rejected, (state, action) => {
        if (state.actionId === action.meta.arg.actionId) {
          state.actionStatus = 'failed';
          state.actionErrorCode = action.payload as ErrorCode;
        }
      })
      .addCase(addSubscription.pending, (state, action) => {
        state.actionStatus = 'loading';
        state.actionErrorCode = null;
        state.actionId = action.meta.arg.actionId;
        state.actionSubscriptionId = undefined;
      })
      .addCase(addSubscription.fulfilled, (state, action) => {
        if (state.actionId === action.meta.arg.actionId) {
          state.actionStatus = 'succeeded';
          state.actionErrorCode = null;
        }

        if (action.payload) {
          state.subscriptions?.push(action.payload)
        }
      })
      .addCase(updateSubscriptionFilters.rejected, (state, action) => {
        if (state.actionId === action.meta.arg.actionId) {
          state.actionStatus = 'failed';
          state.actionErrorCode = action.payload as ErrorCode;
        }
      })
      .addCase(updateSubscriptionFilters.pending, (state, action) => {
        state.actionStatus = 'loading';
        state.actionErrorCode = null;
        state.actionId = action.meta.arg.actionId;
        state.actionSubscriptionId = action.meta.arg.subscriptionId;
      })
      .addCase(updateSubscriptionFilters.fulfilled, (state, action) => {
        if (state.actionId === action.meta.arg.actionId) {
          state.actionStatus = 'succeeded';
          state.actionErrorCode = null;
        }

        if (state.subscriptions) {
          const index = state.subscriptions.findIndex(x => x.channelId === action.meta.arg.channelId && x.subscriptionId === action.meta.arg.subscriptionId)
          if (index !== undefined && index !== -1) {
            state.subscriptions[index].filters = action.meta.arg.filters;
          }
        }
      })
      .addCase(updateSubscriptionNotificationLevel.rejected, (state, action) => {
        if (state.actionId === action.meta.arg.actionId) {
          state.actionStatus = 'failed';
          state.actionErrorCode = action.payload as ErrorCode;
        }
      })
      .addCase(updateSubscriptionNotificationLevel.pending, (state, action) => {
        state.actionStatus = 'loading';
        state.actionErrorCode = null;
        state.actionId = action.meta.arg.actionId;
        state.actionSubscriptionId = action.meta.arg.subscriptionId;
      })
      .addCase(updateSubscriptionNotificationLevel.fulfilled, (state, action) => {
        if (state.actionId === action.meta.arg.actionId) {
          state.actionStatus = 'succeeded';
          state.actionErrorCode = null;
        }
        if (state.subscriptions) {
          const index = state.subscriptions.findIndex(x => x.channelId === action.meta.arg.channelId && x.subscriptionId === action.meta.arg.subscriptionId)
          if (index !== undefined && index !== -1) {
            state.subscriptions[index].notificationLevel = action.meta.arg.notificationLevel;
          }
        }
      })
      .addCase(removeSubscription.rejected, (state, action) => {
        if (state.actionId === action.meta.arg.actionId) {
          state.actionStatus = 'failed';
          state.actionErrorCode = action.payload as ErrorCode;
        }
      })
      .addCase(removeSubscription.pending, (state, action) => {
        state.actionStatus = 'loading';
        state.actionErrorCode = null;
        state.actionId = action.meta.arg.actionId;
        state.actionSubscriptionId = action.meta.arg.subscriptionId;
      })
      .addCase(removeSubscription.fulfilled, (state, action) => {
        if (state.actionId === action.meta.arg.actionId) {
          state.actionStatus = 'succeeded';
          state.actionErrorCode = null;
        }

        if (state.subscriptions) {
          const index = state.subscriptions.findIndex(x => x.channelId === action.meta.arg.channelId && x.subscriptionId === action.meta.arg.subscriptionId)
          if (index !== undefined && index !== -1) {
            state.subscriptions?.splice(index, 1)
          }
        }
      })
  },
})

export const { resetSubscriptions } = subscriptionsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSubscriptionsState = (state: RootState) => state.subscriptions

export default subscriptionsSlice.reducer
