import { DefaultButton, Dropdown, IDropdownOption, IStackTokens, Stack } from '@fluentui/react'
import { FieldType, GalleryPostSection, PostSection, PostSectionType } from '@notidar/api'
import { SectionEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { getFieldByFieldKey } from '../../utils'
import { useTranslation } from 'react-i18next'

const stackTokens: IStackTokens = { childrenGap: 10 }

const isValid = (section: Partial<GalleryPostSection> & PostSection): boolean => {
  return section.mediaField !== undefined
}

export interface GallerySectionEditorComponentProps
  extends SectionEditorComponentProps<PostSectionType.Gallery> { }

export const GallerySectionEditorComponent = ({
  fields,
  hidden,
  onUpdate,
  section,
}: GallerySectionEditorComponentProps) => {
  const { t } = useTranslation();
  const [sectionState, setSectionState] = useState<Partial<GalleryPostSection> & PostSection>(section)

  const allowedImagesFields = fields
    .filter(x => x.type === FieldType.ImageFileList)
    .map(x => ({ key: x.name, text: x.displayName ? `${x.displayName} (${x.name})` : x.name }))

  useEffect(() => {
    onUpdate(sectionState, isValid(sectionState))
  }, [sectionState]) // eslint-disable-line react-hooks/exhaustive-deps

  const onImageFieldChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    const newState: Partial<GalleryPostSection> & PostSection = {
      ...sectionState,
      mediaField: option?.key as string,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onImageFieldClear = (): void => {
    const newState: Partial<GalleryPostSection> & PostSection = {
      ...sectionState,
      mediaField: undefined,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const imagesField = getFieldByFieldKey({ fields }, sectionState.mediaField)

  if (hidden) {
    return null
  }

  return (
    <Stack>
      <Stack tokens={stackTokens} horizontal wrap verticalAlign='end'>
        <Stack.Item grow>
          <Dropdown
            key={sectionState.mediaField}
            disabled={allowedImagesFields.length === 0}
            onChange={onImageFieldChange}
            selectedKey={imagesField ? sectionState.mediaField : undefined}
            placeholder={allowedImagesFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field_placeholder")}
            options={allowedImagesFields}
            label={t("content.sections.gallery.images_field")}
            required
          />
        </Stack.Item>
        <Stack.Item disableShrink grow={0}>
          <DefaultButton
            disabled={!sectionState.mediaField}
            onClick={onImageFieldClear}
            text={t("content.sections.shared.clear")}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  )
}
