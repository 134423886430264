import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ApiClient } from '../../api'
import type { RootState } from '../store'
import { OperationStatus } from '../models'
import { ChannelIdentifier } from '@notidar/content'
import { Channel } from '@notidar/api'

interface UserChannelsState {
  status: OperationStatus
  channels: Channel[] | undefined
}

const initialState: UserChannelsState = {
  status: 'idle',
  channels: undefined,
}

export const getUserChannels = createAsyncThunk(
  'channels/getUserChannels',
  async (_, thunkAPI) => {
    const response = await ApiClient.getUserChannelsAsync({ signal: thunkAPI.signal })
    return response.data.channels
  }
)

export const userChannelsSlice = createSlice({
  name: 'userChannels',
  initialState,
  reducers: {
    addChannel: (state, action: PayloadAction<Channel>) => {
      if(state.channels === undefined) state.channels = []
      state.channels.push(action.payload)
    },
    removeChannel: (state, action: PayloadAction<ChannelIdentifier>) => {
      const index = state.channels?.findIndex(x => x.channelId === action.payload || x.name === action.payload)
      if (index !== undefined && index !== -1) {
        state.channels?.splice(index, 1)
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUserChannels.pending, (state, action) => {
        state.status = 'loading'
        state.channels = []
      })
      .addCase(getUserChannels.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.channels = action.payload
      })
  },
})

export const { addChannel, removeChannel } = userChannelsSlice.actions

export const selectUserChannelsState = (state: RootState) => state.userChannels

export default userChannelsSlice.reducer
