import {
  Dropdown,
  IDropdownOption,
  ITheme,
  PrimaryButton,
  Stack,
  TextField,
  mergeStyleSets,
  useTheme,
} from '@fluentui/react'
import { CurrencyCode, DateTimeField, EnumField, EnumListField, Field, FieldType, MoneyField, NumberField } from '@notidar/api'
import { useState } from 'react'
import { useLocalizedFieldTypes } from '@notidar/content'
import { AllFields } from '@notidar/content/src/utils'
import { useTranslation } from 'react-i18next'

const defaultOptionsMap: Record<FieldType, Omit<Field, "type" | "name">> = {
  [FieldType.Number]: { exponent: 0 } as Omit<NumberField, "type" | "name">,
  [FieldType.String]: {},
  [FieldType.Markdown]: {},
  [FieldType.Link]: {},
  [FieldType.Bool]: {},
  [FieldType.Money]: {} as MoneyField,
  [FieldType.DateTime]: { minimalValidMask: "ymdts" } as DateTimeField,
  [FieldType.Enum]: { possibleValues: [] } as Omit<EnumField, "type" | "name">,
  [FieldType.EnumList]: { possibleValues: [] } as Omit<EnumListField, "type" | "name">,
  [FieldType.StringList]: {},
  [FieldType.LinkList]: {},
  [FieldType.ImageFileList]: {},
  [FieldType.FileList]: {},
  [FieldType.Measurement]: {},
  [FieldType.Location]: {},
  [FieldType.Geography]: {},
  [FieldType.Unknown]: {}
}

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    container: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      padding: '0 10px 0',
    },
  })
}

export interface NewFieldComponentProps {
  existingFields: Field[]
  onAdd: (field: AllFields) => void
}

export const NewFieldComponent = ({ existingFields, onAdd }: NewFieldComponentProps) => {
  const { t } = useTranslation();
  const allowedFieldTypes = useLocalizedFieldTypes();
  const theme = useTheme()
  const classNames = getClassNames(theme)
  const [newField, setNewField] = useState<{
    name?: string | null
    type?: FieldType | null
    isNameValid: boolean
    isTypeValid: boolean
  }>({
    isNameValid: false,
    isTypeValid: false,
  })

  const validateNewFieldName = (value: string): string => {
    if (value.length === 0) {
      return ''
    }

    if (value.length < 4) {
      return t("content.fields.shared.name_too_short");
    }

    if (value.length > 32) {
      return t("content.fields.shared.name_too_long");
    }

    const regexp = new RegExp('^[a-z0-9]{1}[a-z0-9_]{2,}[a-z0-9]{1}$')
    if (!regexp.test(value)) {
      return t("content.fields.shared.name_invalid_format");
    }

    if (existingFields.some(x => x.name === value)) {
      return t("content.fields.shared.name_exists");
    }

    setNewField({ ...newField, isNameValid: true })
    return ''
  }

  const addNewField = (): void => {
    const fieldToCreate: AllFields = {
      type: newField.type!,
      name: newField.name!,
      ...defaultOptionsMap[newField.type!],
    }
    onAdd(fieldToCreate)
    setNewField(x => ({ isNameValid: false, isTypeValid: false, name: null, type: null }))
  }

  const newFieldNameChange = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setNewField({ ...newField, name: newValue, isNameValid: false })
  }

  const newFieldTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    const type = option?.key as FieldType | undefined
    setNewField({ ...newField, type: type, isTypeValid: type !== undefined })
  }

  return (
    <Stack className={classNames.container}>
      <Stack tokens={{ childrenGap: 10 }}>
        <Dropdown
          label={t("content.fields.shared.type_label")}
          selectedKey={newField.type}
          onChange={newFieldTypeChange}
          placeholder={t("content.fields.shared.type_placeholder")}
          options={allowedFieldTypes}
        />
        <TextField
          label={t("content.fields.shared.name_label")}
          value={newField.name ?? ''}
          placeholder={t("content.fields.shared.name_placeholder")}
          validateOnLoad={false}
          onChange={newFieldNameChange}
          deferredValidationTime={500}
          onGetErrorMessage={validateNewFieldName}
        />
        <Stack horizontal>
          <PrimaryButton
            disabled={!(newField.isNameValid && newField.isTypeValid)}
            onClick={addNewField}
            text={t("content.fields.shared.add_field")}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
