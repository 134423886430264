import { ISpinButtonStyleProps, ISpinButtonStyles, IStyleFunction, Position, SpinButton, Stack } from '@fluentui/react'
import { useCallback, useContext, useMemo, useState } from 'react'
import { AllFields } from '../../../utils'
import { MeasurementFilter, MeasurementField, FilterOperationType } from '@notidar/api'
import { useTranslation } from 'react-i18next'
import { unitDataMap } from '../../../fields/measurement/MeasurementValueComponent'
import { MeasurementConversionContext } from '../../../fields/measurement/MeasurementConversionContext'
import { toMeasurementNumber, toMeasurementString } from '../../../fields/measurement/utils'

export interface MeasurementFilterComponentProps {
  filter: MeasurementFilter
  fields: AllFields[]
  onSubmit: (filter: MeasurementFilter) => void
}

export enum RoundingType {
  Round,
  Floor,
  Ceil
}

const spinButtonStyles: IStyleFunction<ISpinButtonStyleProps, ISpinButtonStyles> = (props: ISpinButtonStyleProps) => {
  return { labelWrapper: { whiteSpace: "nowrap", width: "100%" }, label: { overflow: "hidden", textOverflow: "ellipsis" } };
};

export const MeasurementFilterComponent = ({ filter, fields, onSubmit }: MeasurementFilterComponentProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { getConverter } = useContext(MeasurementConversionContext);
  const [state, setState] = useState<{ lowerLimit?: number, upperLimit?: number } | undefined | null>({ lowerLimit: filter.lowerLimit ?? undefined, upperLimit: filter.upperLimit ?? undefined });
  const field = useMemo(() => fields.find(y => y.name === filter.field) as MeasurementField, [filter, fields]);

  const converter = getConverter?.(field.name);
  const originalUnitData = unitDataMap[field.unit];
  const targetUnitData = converter?.targetUnit ? unitDataMap[converter?.targetUnit] : undefined;

  const getOperationType = (lowerLimit?: number, upperLimit?: number): FilterOperationType => {
    if (lowerLimit === undefined) {
      return FilterOperationType.Lte;
    }
    if (upperLimit === undefined) {
      return FilterOperationType.Gte;
    }
    return FilterOperationType.Between;
  }

  const round = (value: number, roundingType?: RoundingType): number => {
    switch (roundingType ?? RoundingType.Round) {
      case RoundingType.Round:
        return Math.round(value);
      case RoundingType.Floor:
        return Math.floor(value);
      case RoundingType.Ceil:
        return Math.ceil(value);
    }
  }

  const onFilterChange = useCallback((newLower?: { value?: number, roundingType?: RoundingType }, newUpper?: { value?: number, roundingType?: RoundingType }) => {
    let convertedNewLower = newLower !== undefined
      ? (converter && newLower?.value !== undefined 
        ? round(converter.revert(newLower.value), newLower.roundingType)
        : newLower?.value) 
      : state?.lowerLimit;

    let convertedNewUpper = newUpper !== undefined
      ? (converter && newUpper?.value !== undefined 
        ? round(converter.revert(newUpper.value), newUpper.roundingType)
        : newUpper?.value) 
      : state?.upperLimit;

    if (convertedNewLower !== undefined && convertedNewUpper !== undefined) {
      if(convertedNewLower > convertedNewUpper) {
        if(newLower !== undefined) {
          convertedNewUpper = convertedNewLower;
        } else {
          convertedNewLower = convertedNewUpper;
        }
      }
    }

    setState({
      ...state,
      lowerLimit: convertedNewLower,
      upperLimit: convertedNewUpper
    });
    onSubmit({ ...filter, lowerLimit: convertedNewLower, upperLimit: convertedNewUpper, operationType: getOperationType(convertedNewLower, convertedNewUpper) });
  }, [setState, onSubmit, state]);

  const onLowerChange = useCallback((event?: React.SyntheticEvent<HTMLElement>, value?: number, roundingType?: RoundingType) => {
    onFilterChange({ value, roundingType }, undefined);
  }, [onFilterChange]);

  const onLowerStringChanged = useCallback((event?: React.SyntheticEvent<HTMLElement>, newValue?: string, increment?: -1 | 1) => {
    const unitData = targetUnitData ?? originalUnitData;
    let newLowerLimitNormalized = newValue === undefined ? undefined : toMeasurementNumber(newValue, unitData?.subunitDivider, unitData?.assumeLeftPart);
    newLowerLimitNormalized = newLowerLimitNormalized !== undefined ? newLowerLimitNormalized + (increment ?? 0) : undefined;
    const roundingType = increment === undefined ? RoundingType.Round : increment > 0 ? RoundingType.Ceil : RoundingType.Floor;

    onLowerChange(event, newLowerLimitNormalized, roundingType);
  }, [onLowerChange]);

  const onUpperChange = useCallback((event?: React.SyntheticEvent<HTMLElement>, value?: number, roundingType?: RoundingType) => {
    onFilterChange(undefined, { value, roundingType });
  }, [onFilterChange]);

  const onUpperStringChanged = useCallback((event?: React.SyntheticEvent<HTMLElement>, newValue?: string, increment?: -1 | 1) => {
    const unitData = targetUnitData ?? originalUnitData;
    let newUpperLimitNormalized = newValue === undefined ? undefined : toMeasurementNumber(newValue, unitData?.subunitDivider, unitData?.assumeLeftPart);
    newUpperLimitNormalized = newUpperLimitNormalized !== undefined ? newUpperLimitNormalized + (increment ?? 0) : undefined;
    const roundingType = increment === undefined ? RoundingType.Round : increment > 0 ? RoundingType.Ceil : RoundingType.Floor;

    onUpperChange(event, newUpperLimitNormalized, roundingType);
  }, [onUpperChange]);

  const onValidate = useCallback((value: string): string | void => {
    const unitData = targetUnitData ?? originalUnitData;
    const int = toMeasurementNumber(value, unitData.subunitDivider, unitData.assumeLeftPart);
    return toMeasurementString(int, unitData.unit, unitData.subunitDivider, unitData.subunitSymbol, true) ?? '';
  }, []);

  const lowerLimitDisplayString = (converter && targetUnitData
    ? toMeasurementString(
      state?.lowerLimit !== undefined
        ? Math.round(converter.convert(state.lowerLimit))
        : undefined,
      targetUnitData.symbol,
      targetUnitData.subunitDivider,
      targetUnitData.subunitSymbol,
      true)
    : toMeasurementString(
      state?.lowerLimit,
      originalUnitData.symbol,
      originalUnitData.subunitDivider,
      originalUnitData.subunitSymbol,
      true)) || '';

  const upperLimitDisplayString = (converter && targetUnitData
    ? toMeasurementString(
      state?.upperLimit !== undefined
        ? Math.round(converter.convert(state.upperLimit))
        : undefined,
      targetUnitData.symbol,
      targetUnitData.subunitDivider,
      targetUnitData.subunitSymbol,
      true)
    : toMeasurementString(
      state?.upperLimit,
      originalUnitData.symbol,
      originalUnitData.subunitDivider,
      originalUnitData.subunitSymbol,
      true)) || '';

  return (
    <Stack tokens={{ childrenGap: 10 }} horizontal>
      <SpinButton
        styles={spinButtonStyles}
        labelPosition={Position.top}
        label={t("content.filters.number.from", { field: field.displayName ?? field.name })}
        value={lowerLimitDisplayString}
        onChange={onLowerStringChanged}
        onValidate={onValidate}
        onIncrement={(value, event) => onLowerStringChanged(event, value ? value : '0', 1)}
        onDecrement={(value, event) => onLowerStringChanged(event, value ? value : '0', -1)}
      />
      <SpinButton
        styles={spinButtonStyles}
        labelPosition={Position.top}
        label={t("content.filters.number.to", { field: field.displayName ?? field.name })}
        value={upperLimitDisplayString}
        onChange={onUpperStringChanged}
        onValidate={onValidate}
        onIncrement={(value, event) => onUpperStringChanged(event, value ? value : '0', 1)}
        onDecrement={(value, event) => onUpperStringChanged(event, value ? value : '0', -1)}
      />
    </Stack>
  );
}
