import {
  ChoiceGroup,
  DatePicker,
  DefaultButton,
  defaultDatePickerStrings,
  IChoiceGroupOption,
  IComboBox,
  IStackTokens,
  Stack,
  TimePicker,
  TimePickerValidationResultData,
} from '@fluentui/react'
import { FieldType } from '@notidar/api'
import { ValueEditorProps } from '../Shared.types'
import { ItemHeader } from '../shared/ItemHeader'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const stackTokens: IStackTokens = { childrenGap: 10 }

export interface DateTimeValueEditorProps extends ValueEditorProps<FieldType.DateTime> { }

export const DateTimeValueEditor = ({
  field,
  value,
  onUpdate,
  onValidation,
}: DateTimeValueEditorProps) => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const { t, i18n } = useTranslation();

  const minimalValidMask = field?.minimalValidMask ?? "ymdts";
  const options: IChoiceGroupOption[] = [
    { key: 'y', text: t("content.fields.datetime.precision_year"), iconProps: { iconName: 'Calendar' }, disabled: !'y'.startsWith(minimalValidMask) },
    { key: 'ym', text: t("content.fields.datetime.precision_month"), iconProps: { iconName: 'CalendarWeek' }, disabled: !'ym'.startsWith(minimalValidMask) },
    { key: 'ymd', text: t("content.fields.datetime.precision_day"), iconProps: { iconName: 'CalendarDay' }, disabled: !'ymd'.startsWith(minimalValidMask) },
    { key: 'ymdt', text: t("content.fields.datetime.precision_time"), iconProps: { iconName: 'DateTime' }, disabled: !'ymdt'.startsWith(minimalValidMask) },
    { key: 'ymdts', text: t("content.fields.datetime.precision_time_accurate"), iconProps: { iconName: 'Timer' }, disabled: !'ymdts'.startsWith(minimalValidMask) },
  ];

  const [valueState, setValueState] = useState<{ value?: Date, mask: string }>(
    {
      value: value?.dateTimePayload?.value ? new Date(value?.dateTimePayload?.value) : undefined,
      mask: value?.dateTimePayload?.mask ?? minimalValidMask,
    }
  )

  const onFormatDate = (date?: Date): string => {
    const options : Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: valueState?.mask.startsWith('ym') ? 'long' : undefined,
      day: valueState?.mask.startsWith('ymd') ? 'numeric' : undefined,
    };
    return !date ? '' : date.toLocaleDateString(i18n.language, options);
  };

  const onReset = (): void => {
    setValueState({ mask: minimalValidMask })
    onUpdate(field, { type: FieldType.DateTime, dateTimePayload: { value: undefined, mask: minimalValidMask } })
    onValidation(field, true)
  }

  const onNow = (): void => {
    const newState: { value: Date, mask: string } = {
      ...valueState,
      value: new Date(),
    }
    setValueState(newState)
    onUpdate(field, { type: FieldType.DateTime, dateTimePayload: { value: newState.value.toJSON(), mask: newState.mask } })
    onValidation(field, true)
  }

  const onDateChange = (date: Date | null | undefined): void => {
    if (date) {
      if (valueState.value) {
        date.setHours(valueState.value.getHours())
        date.setMinutes(valueState.value.getMinutes())
        date.setSeconds(valueState.value.getSeconds())
        date.setMilliseconds(valueState.value.getMilliseconds())
      }
      const newState: { value: Date, mask: string } = {
        ...valueState,
        value: date,
      }
      setValueState(newState)
      onUpdate(field, { type: FieldType.DateTime, dateTimePayload: { value: newState.value.toJSON(), mask: newState.mask } })
    } else {
      setValueState({ mask: minimalValidMask })
      onUpdate(field, { type: FieldType.DateTime, dateTimePayload: { value: undefined, mask: minimalValidMask } })
    }
    onValidation(field, isValid)
  }

  const onMaskChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
    if (option?.key) {
      const newState: { value?: Date, mask: string } = {
        ...valueState,
        mask: option.key,
      }
      setValueState(newState)
      onUpdate(field, { type: FieldType.DateTime, dateTimePayload: { value: newState.value?.toJSON(), mask: newState.mask } })
      onValidation(field, isValid)
    }
  }

  const onTimeChange = (_: React.FormEvent<IComboBox>, time: Date): void => {
    if (isFinite(time.getTime())) {
      let newDateTime = valueState.value ? new Date(valueState.value) : new Date()
      newDateTime.setHours(time.getHours())
      newDateTime.setMinutes(time.getMinutes())
      newDateTime.setSeconds(time.getSeconds())
      newDateTime.setMilliseconds(time.getMilliseconds())
      const newState: { value: Date, mask: string } = {
        ...valueState,
        value: newDateTime,
      }
      setValueState(newState)
      onUpdate(field, { type: FieldType.DateTime, dateTimePayload: { value: newState.value.toJSON(), mask: newState.mask } })
      onValidation(field, isValid)
    }
  }

  const onValidationResult = (
    _: React.FormEvent<IComboBox>,
    data: TimePickerValidationResultData
  ) => {
    if (data.errorMessage) {
      setIsValid(false)
      onValidation(field, false)
    } else {
      setIsValid(true)
      onValidation(field, true)
    }
  }

  return (
    <Stack>
      <ItemHeader name={field.name} title={field.displayName} />
      <Stack tokens={stackTokens} horizontal wrap>
        <DatePicker
          style={{ minWidth: 200, margin: '10px 5px' }}
          placeholder={t("content.fields.datetime.pick_date")}
          value={valueState.value}
          formatDate={onFormatDate}
          strings={{
            ...defaultDatePickerStrings,
            goToToday: t("content.fields.datetime.today"),
          }}
          onSelectDate={onDateChange}
        />
        <TimePicker
          style={{ margin: '10px 5px' }}
          key={valueState.value?.toJSON()}
          placeholder={t("content.fields.datetime.pick_time")}
          value={valueState?.mask.startsWith('ymdt') ? valueState.value : undefined}
          allowFreeform
          showSeconds={valueState?.mask.startsWith('ymdts')}
          dateAnchor={valueState.value}
          onChange={onTimeChange}
          onValidationResult={onValidationResult}
          disabled={!valueState?.mask.startsWith('ymdt')}
        />
        <DefaultButton style={{ margin: '10px 5px' }} onClick={onNow} text={t("content.fields.datetime.now")} />
        <DefaultButton style={{ margin: '10px 5px' }} onClick={onReset} text={t("content.fields.datetime.clear")} />
      </Stack>
      <ChoiceGroup selectedKey={valueState.mask} onChange={onMaskChange} label={t("content.fields.datetime.pick_precision")} options={options} />
    </Stack>
  )
}
