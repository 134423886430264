import { BoolValue, DateTimeValue, EnumListValue, EnumValue, FieldType, FileListValue, ImageFileListValue, LinkListValue, LinkValue, MarkdownValue, MeasurementValue, MoneyValue, NumberValue, StringListValue, StringValue, Value } from "@notidar/api";

export function notEmptyValue(value: Value): boolean {
  switch (value.type) {
    case FieldType.ImageFileList:
      return ((value as ImageFileListValue)?.imageFileListPayload?.fileIds?.length ?? 0) > 0;
    case FieldType.FileList:
      return ((value as FileListValue)?.fileListPayload?.fileIds?.length ?? 0) > 0;
    case FieldType.Enum:
      return !!((value as EnumValue)?.enumPayload?.enumId?.trim());
    case FieldType.EnumList:
      return ((value as EnumListValue)?.enumListPayload?.enumIds?.length ?? 0) > 0;
    case FieldType.DateTime:
      return !!((value as DateTimeValue)?.dateTimePayload?.value);
    case FieldType.String:
      return !!((value as StringValue)?.stringPayload?.value?.trim());
    case FieldType.StringList:
      return ((value as StringListValue)?.stringListPayload?.values?.length ?? 0) > 0;
    case FieldType.Markdown:
      return !!((value as MarkdownValue)?.markdownPayload?.value?.trim());
    case FieldType.Link:
      return !!((value as LinkValue)?.linkPayload?.link?.trim());
    case FieldType.LinkList:
      return ((value as LinkListValue)?.linkListPayload?.links?.length ?? 0) > 0;
    case FieldType.Bool:
      const boolValue = (value as BoolValue)?.boolPayload?.value;
      return boolValue !== undefined && boolValue !== null;
    case FieldType.Money:
      const moneyValue = (value as MoneyValue)?.moneyPayload?.value;
      return moneyValue !== undefined && moneyValue !== null;
    case FieldType.Number:
      const numberValue = (value as NumberValue)?.numberPayload?.value;
      return numberValue !== undefined && numberValue !== null;
    case FieldType.Measurement:
      const measurementValue = (value as MeasurementValue)?.measurementPayload?.value;
      return measurementValue !== undefined && measurementValue !== null;
    default:
      return false
  }
}