import { MeasurementSystem, Unit } from "@notidar/api";

export type Convert = (value: number) => number;

export type Converter = {
  convert: Convert,
  revert: Convert,
  targetUnit: Unit,
  originalUnit: Unit
}

export const toCelsiusConversionMap = new Map<Unit, Converter>(
  [
    [Unit.TemperatureFahrenheit, {
      convert: (value) => (value - 32) * 5 / 9,
      revert: (value) => value * 9 / 5 + 32,
      targetUnit: Unit.TemperatureCelsius,
      originalUnit: Unit.TemperatureFahrenheit
    }],
    [Unit.TemperatureKelvin, {
      convert: (value) => value - 273.15,
      revert: (value) => value + 273.15,
      targetUnit: Unit.TemperatureCelsius,
      originalUnit: Unit.TemperatureKelvin,
    }],
  ]
);

export const toFahrenheitConversionMap = new Map<Unit, Converter>(
  [
    [Unit.TemperatureCelsius, {
      convert: (value) => value * 9 / 5 + 32,
      revert: (value) => (value - 32) * 5 / 9,
      targetUnit: Unit.TemperatureFahrenheit,
      originalUnit: Unit.TemperatureCelsius,
    }],
    [Unit.TemperatureKelvin, {
      convert: (value) => (value - 273.15) * 9 / 5 + 32,
      revert: (value) => (value - 32) * 5 / 9 + 273.15,
      targetUnit: Unit.TemperatureFahrenheit,
      originalUnit: Unit.TemperatureKelvin,
    }],
  ]
);

export const toKelvinConversionMap = new Map<Unit, Converter>(
  [
    [Unit.TemperatureCelsius, {
      convert: (value) => value + 273.15,
      revert: (value) => value - 273.15,
      targetUnit: Unit.TemperatureKelvin,
      originalUnit: Unit.TemperatureCelsius,
    }],
    [Unit.TemperatureFahrenheit, {
      convert: (value) => (value - 32) * 5 / 9 + 273.15,
      revert: (value) => (value - 273.15) * 9 / 5 + 32,
      targetUnit: Unit.TemperatureKelvin,
      originalUnit: Unit.TemperatureFahrenheit,
    }],
  ]
);

export const temperatureConversionMap = new Map<Unit, Map<Unit, Converter>>(
  [
    [Unit.TemperatureCelsius, toCelsiusConversionMap],
    [Unit.TemperatureFahrenheit, toFahrenheitConversionMap],
    [Unit.TemperatureKelvin, toKelvinConversionMap],
  ]
);

export const toMetricConversionMap = new Map<Unit, Converter>(
  [
    ...toCelsiusConversionMap,
    [Unit.LengthInch, {
      convert: (value) => value * 2.54,
      revert: (value) => value / 2.54,
      targetUnit: Unit.LengthCentimeter,
      originalUnit: Unit.LengthInch,
    }],
    [Unit.LengthFoot, {
      convert: (value) => value * 0.3048,
      revert: (value) => value / 0.3048,
      targetUnit: Unit.LengthMeter,
      originalUnit: Unit.LengthFoot,
    }],
    [Unit.LengthFootAndInch, {
      convert: (value) => value * 2.54,
      revert: (value) => value / 2.54,
      targetUnit: Unit.LengthMeterAndCentimeter,
      originalUnit: Unit.LengthFootAndInch,
    }],
    [Unit.AreaSquareFoot, {
      convert: (value) => value * 0.09290304,
      revert: (value) => value / 0.09290304,
      targetUnit: Unit.AreaSquareMeter,
      originalUnit: Unit.AreaSquareFoot,
    }],
  ]
);

export const toImperialConversionMap = new Map<Unit, Converter>(
  [
    ...toFahrenheitConversionMap,
    [Unit.LengthCentimeter, {
      convert: (value) => value * 0.393700787,
      revert: (value) => value / 0.393700787,
      targetUnit: Unit.LengthInch,
      originalUnit: Unit.LengthCentimeter,
    }],
    [Unit.LengthMeter, {
      convert: (value) => value * 3.2808399,
      revert: (value) => value / 3.2808399,
      targetUnit: Unit.LengthFoot,
      originalUnit: Unit.LengthMeter,
    }],
    [Unit.LengthMeterAndCentimeter, {
      convert: (value) => value * 0.393700787,
      revert: (value) => value / 0.393700787,
      targetUnit: Unit.LengthFootAndInch,
      originalUnit: Unit.LengthMeterAndCentimeter,
    }],
    [Unit.AreaSquareMeter, {
      convert: (value) => value * 10.7639104,
      revert: (value) => value / 10.7639104,
      targetUnit: Unit.AreaSquareFoot,
      originalUnit: Unit.AreaSquareMeter,
    }],
  ]
);

export const systemConversionMap = new Map<MeasurementSystem, Map<Unit, Converter>>(
  [
    [MeasurementSystem.Metric, toMetricConversionMap],
    [MeasurementSystem.Imperial, toImperialConversionMap],
  ]
);