import { Checkbox, Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react'
import { CurrencyCode, FieldType, MoneyField } from '@notidar/api'
import { FieldEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const options: IDropdownOption[] = [
  { key: CurrencyCode.USD, text: CurrencyCode.USD },
  { key: CurrencyCode.EUR, text: CurrencyCode.EUR },
  { key: CurrencyCode.PLN, text: CurrencyCode.PLN },
  { key: CurrencyCode.RUB, text: CurrencyCode.RUB },
  { key: CurrencyCode.CAD, text: CurrencyCode.CAD },
  { key: CurrencyCode.NOK, text: CurrencyCode.NOK },
]

export interface MoneyFieldEditorComponentProps extends FieldEditorComponentProps<FieldType.Money> { }

export const MoneyFieldEditorComponent = ({ field, hidden, onUpdate }: MoneyFieldEditorComponentProps) => {
  const [fieldState, setFieldState] = useState<MoneyField>(field);
  const { t } = useTranslation();

  const isValid = (field: MoneyField): boolean => {
    const isCurrencyValid = field.currency !== undefined
    return isCurrencyValid && validateTitle(field.displayName ?? '') === ''
  }

  const validateTitle = (value: string): string => {
    if (value.length === 0) {
      return ''
    }

    if (value.length > 32) {
      return t("content.fields.shared.displayName_too_long");
    }

    return ''
  }

  useEffect(() => {
    onUpdate(fieldState, isValid(fieldState))
  }, [fieldState]) // eslint-disable-line react-hooks/exhaustive-deps

  const titleValueChanged = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    const newField: MoneyField = { ...fieldState, displayName: newValue }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  const currencyValueChanged = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    const newField: MoneyField = {
      ...fieldState,
      currency: option?.key as CurrencyCode,
    }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  const searchableValueChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    const newField: MoneyField = { ...fieldState, searchable: checked }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  if (hidden) {
    return null
  }

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <TextField
        label={t("content.fields.shared.displayName")}
        value={fieldState.displayName ?? ''}
        onChange={titleValueChanged}
        onGetErrorMessage={validateTitle}
        deferredValidationTime={500}
      />
      <Dropdown
        label={t("content.fields.money.currency")}
        placeholder={t("content.fields.money.pick_currency")}
        options={options}
        selectedKey={fieldState.currency}
        onChange={currencyValueChanged}
      />
      <Checkbox label={t("content.fields.shared.searchable")} checked={fieldState.searchable} onChange={searchableValueChanged} />
    </Stack>
  )
}
