import {
  Stack,
  Persona,
  PersonaSize,
  IconButton,
  IButtonStyles,
  IPersonaStyles,
  IStyleFunctionOrObject,
  IPersonaStyleProps,
  IStackStyles,
  PersonaPresence,
  useTheme,
  ITheme,
} from '@fluentui/react'
import { useAccount } from '@azure/msal-react'
import { useOnSignOut, useOnSignIn, useMenuOnClickNavigate } from '../../../hooks'
import { useTranslation } from 'react-i18next'

const getPersonaIconStyles = (theme: ITheme): IStyleFunctionOrObject<IPersonaStyleProps, IPersonaStyles> => {
  return {
    root: {
      width: 60,
      height: 60,
      cursor: 'pointer',
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover, :active, :active:hover': {
        background: theme.palette.neutralLighter,
      },
    },
  }
}

const buttonIconStyles: IButtonStyles = {
  root: {
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 20,
  },
}

const stackStyles: IStackStyles = {
  root: {
    height: '100%',
  },
}

export const LeftPanel = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const account = useAccount()
  const onSignOut = useOnSignOut(account)
  const onSignIn = useOnSignIn()
  const menuOnClickNavigate = useMenuOnClickNavigate()

  const personaIconStyles = getPersonaIconStyles(theme)

  return (
    <Stack styles={stackStyles} verticalAlign='space-between'>
      {account ? (
        <>
          <Stack>
            <Persona
              styles={personaIconStyles}
              text={account?.username}
              hidePersonaDetails={true}
              size={PersonaSize.size40}
              onClick={menuOnClickNavigate('/user/me')}
              presence={PersonaPresence.online}
            />
            <IconButton
              onClick={menuOnClickNavigate('/settings')}
              styles={buttonIconStyles}
              iconProps={{ iconName: 'Settings' }}
              title={t('left_bar.settings')}
              ariaLabel={t('left_bar.settings')}
            />
          </Stack>
          <Stack>
            <IconButton
              onClick={onSignOut}
              styles={buttonIconStyles}
              iconProps={{ iconName: 'SignOut' }}
              title={t('left_bar.sign_out')}
              ariaLabel={t('left_bar.sign_out')}
            />
          </Stack>
        </>
      ) : (
        <Stack>
          <IconButton
            onClick={onSignIn}
            styles={buttonIconStyles}
            iconProps={{ iconName: 'SignIn' }}
            title={t('left_bar.sign_in')}
            ariaLabel={t('left_bar.sign_in')}
          />
        </Stack>
      )}
    </Stack>
  )
}
