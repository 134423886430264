import { mergeStyleSets } from '@fluentui/react'
import { useTranslation } from 'react-i18next';

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      display: 'flex',
      padding: '0px 20px 0px 32px',
      lineHeight: "44px",
    },
  })
}

export const Version = () => {
  const classNames = getClassNames();
  const { t } = useTranslation();

  return (
    <div className={classNames.container}>
      <span>{t("menu.version", { version: process.env.REACT_APP_VERSION })}</span>
    </div>
  )
}
