import { ChoiceGroup, IChoiceGroupOption, ITheme, Label, mergeStyleSets, MessageBar, MessageBarType, Stack, useTheme } from '@fluentui/react'
import { ErrorCode, NotificationLevel } from '@notidar/api'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    error: {
      color: theme.semanticColors.errorText,
    }
  })
}

export interface SubscriptionNotificationEditComponentProps {
  notificationLevel: NotificationLevel
  onChange: (notificationLevel: NotificationLevel) => void
  loading: boolean,
  errorCode: ErrorCode | null,
}

export const SubscriptionNotificationEditComponent = ({ errorCode, loading, notificationLevel, onChange }: SubscriptionNotificationEditComponentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classNames = getClassNames(theme);

  const options: IChoiceGroupOption[] = [
    { key: NotificationLevel.None, text: t("types.notification_level.none") },
    { key: NotificationLevel.HighImportance, text: t("types.notification_level.high_importance") },
    { key: NotificationLevel.All, text: t("types.notification_level.all") },
  ];

  const onChangeInternal = useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    const selectedKey = option?.key as NotificationLevel;
    if (selectedKey) {
      onChange(selectedKey);
    }
  }, [onChange]);

  return (
    <Stack verticalAlign='space-between'>
      <Stack tokens={{ childrenGap: 10 }}>
        <ChoiceGroup disabled={loading} selectedKey={notificationLevel} options={options} onChange={onChangeInternal} label={t('right_bar.notifications.label')} />
        <MessageBar messageBarType={MessageBarType.info}>
          {t('right_bar.notifications.note')}
        </MessageBar>
        {errorCode != null ? <Label className={classNames.error}>{t('shared.errors.SUBSCRIPTION_NOTIFICATION_LIMIT_REACHED')}</Label> : null}
      </Stack>
    </Stack>
  )
}
