import { DateTimeField, DateTimeValue } from "@notidar/api";
import { useTranslation } from "react-i18next";

export interface DateTimeValueComponentProps {
  field: DateTimeField
  value: DateTimeValue
}

export const DateTimeValueComponent = ({ field, value }: DateTimeValueComponentProps): JSX.Element | null => {
  const { t, i18n } = useTranslation();

  const datetimeValue = value.dateTimePayload?.value;
  if (datetimeValue === undefined || datetimeValue === null) {
    return null;
  }

  const datetimeDisplayValue = new Date(datetimeValue);
  const datetimeMask = value.dateTimePayload?.mask;

  const datePart = `${datetimeDisplayValue.toLocaleDateString(
    i18n.language, 
    {
      year: 'numeric',
      month: datetimeMask?.startsWith('ym') ? 'long' : undefined,
      day: datetimeMask?.startsWith('ymd') ? 'numeric' : undefined,
    }
  )}`;

  const timePart = datetimeMask?.startsWith('ymdt')
    ? ` ${datetimeDisplayValue.toLocaleTimeString(
      i18n.language, 
      {
        hour: 'numeric',
        minute: 'numeric',
        second: datetimeMask?.startsWith('ymdts') ? 'numeric' : undefined,
      }
    )}` : null

  return <span>{datePart}{timePart}</span>
}