import { ChannelIdentifier } from '@notidar/content'
import { PrimaryButton, Stack, mergeStyleSets, Separator, Label, useTheme, ITheme } from '@fluentui/react'
import { Fragment, useState } from 'react'
import { ApiClient } from '../../../api'
import { useNavigate } from '../../../hooks'
import { ErrorCode, ErrorCodes, Field, getErrorMessageCode } from '@notidar/api'
import { FieldEditorComponentContainer } from './FieldEditorComponentContainer'
import { NewFieldComponent } from './NewFieldComponent'
import { AllFields } from '@notidar/content/src/utils'
import { useTranslation } from 'react-i18next'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    button: {
      margin: '0 10px 0 0',
    },
    footer: {
      margin: '0 10px 10px',
    },
    text: {
      margin: 10,
    },
    error: {
      color: theme.semanticColors.errorText,
    }
  })
}

export interface FieldListProps {
  existingFields: Field[]
  channelIdentifier: ChannelIdentifier
}

export const FieldList: React.FC<FieldListProps> = ({
  existingFields,
  channelIdentifier,
}: FieldListProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classNames = getClassNames(theme)
  const navigate = useNavigate()
  const [version, setVersion] = useState(0);
  const [errorCode, setErrorCode] = useState<ErrorCode | null>(null);
  const [fields, setFields] = useState<{ field: Field; isValid?: boolean }[]>(
    existingFields.map(x => ({ field: x, isValid: true }))
  )

  const addNewField = (field: AllFields): void => {
    setFields([...fields, { field: { ...field } }])
  }

  const onFieldRemove = (field: Field): void => {
    setFields(fields.filter((x, _) => x.field.name !== field.name))
  }

  const onFieldUpdate = (field: Field, isValid: boolean): void => {
    const newFields = [...fields]
    const index = newFields.findIndex(x => x.field.name === field.name)
    newFields[index].field = field
    newFields[index].isValid = isValid
    setFields(newFields)
  }

  const onReset = (): void => {
    setFields(existingFields.map(x => ({ field: x, isValid: true })));
    setVersion(version + 1);
  }

  const onChannelFieldsUpdate = async (): Promise<void> => {
    try {
      setErrorCode(null);
      await ApiClient.updateChannelFieldsAsync(channelIdentifier, {
        fields: fields.map(x => x.field) as Field[],
      })
      navigate(`/manage/channel/${channelIdentifier}`)
    } catch (e: unknown) {
      setErrorCode(getErrorMessageCode(e));
    }
  }

  return (
    <>
      <Fragment key={version}>
        {fields.length > 0 ? fields.map(x => (
          <FieldEditorComponentContainer
            key={x.field.name}
            field={x.field}
            onRemove={onFieldRemove}
            onUpdate={onFieldUpdate}
          />
        )) : <div className={classNames.text}>{t('pages.manage_fields.no_fields')}</div>}
      </Fragment>
      <NewFieldComponent existingFields={fields.map(x => x.field)} onAdd={addNewField} />
      <Separator />
      <Stack className={classNames.footer} horizontal>
        <PrimaryButton
          disabled={fields.some(x => !x.isValid)}
          className={classNames.button}
          onClick={onChannelFieldsUpdate}
          text={t('pages.manage_fields.save')}
        />
        <PrimaryButton
          className={classNames.button}
          onClick={onReset}
          text={t('pages.manage_fields.reset')}
        />
      </Stack>
      <div className={classNames.footer}>
        {errorCode === ErrorCodes.CHANNEL_FIELD_LIMIT_REACHED ? <Label className={classNames.error}>{t('shared.errors.CHANNEL_FIELD_LIMIT_REACHED')}</Label> : null}
        {errorCode === ErrorCodes.UNKNOWN ? <Label className={classNames.error}>{t('shared.errors.UNKNOWN')}</Label> : null}
      </div>
    </>
  )
}
