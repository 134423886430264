import { Stack, TextField } from '@fluentui/react'
import { FieldType } from '@notidar/api'
import { ValueEditorProps } from '../Shared.types'
import { ItemHeader } from '../shared/ItemHeader'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface StringValueEditorProps extends ValueEditorProps<FieldType.String> { }

const getValidationMessage = (value: string | undefined): string => {
  return ''
}

const isValid = (value: string | undefined): boolean => {
  return getValidationMessage(value) === ''
}

export const StringValueEditor = ({
  field,
  value,
  onUpdate,
  onValidation,
}: StringValueEditorProps) => {
  const [newValue, setNewValue] = useState<string | undefined>(value?.stringPayload?.value ?? undefined);
  const { t } = useTranslation();

  const onValueChange = (
    _?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const result = newValue || ''
    setNewValue(result)
    onUpdate(field, { type: FieldType.String, stringPayload: { value: result } })
    onValidation(field, isValid(result))
  }

  return (
    <Stack>
      <ItemHeader name={field.name} title={field.displayName} />
      <Stack>
        <TextField
          value={newValue}
          placeholder={t('content.fields.string.placeholder')}
          validateOnLoad={false}
          deferredValidationTime={500}
          onGetErrorMessage={getValidationMessage}
          onChange={onValueChange}
        />
      </Stack>
    </Stack>
  )
}
