import { PrimaryButton, Separator, Stack, Toggle, mergeStyleSets } from '@fluentui/react'
import { Channel, Field, Value } from '@notidar/api'
import { useState } from 'react'
import { FieldKey, ValueEditorComponent } from '@notidar/content'
import { PostCard } from '@notidar/content/src/cards/PostCard'
import { PostContentContext } from '@notidar/content/src/context/postContentContext'
import {
  notEmptyValue,
} from '@notidar/content'
import { MeasurementConversionContext } from '@notidar/content/src/fields/measurement/MeasurementConversionContext'
import { useMeasurementConversionContextState } from '../../hooks'

export interface ValueListEditorProps {
  channel: Channel
  postId?: string,
  values?: Record<FieldKey, Value>
  disabled?: boolean
  onSave: (values: Record<FieldKey, Value>) => void
  onSaveText: string
  onDelete?: () => void
  onDeleteText?: string
  previewOnText: string
  previewOffText: string
}

const getClassNames = () => {
  return mergeStyleSets({
    actionButton: {
      margin: '0 10px 0 0',
    },
    toggle: {
      margin: '0',
    },
  })
}

export const ValueListEditor = ({ channel, values, disabled, onSave, onDelete, onDeleteText, onSaveText, previewOffText, previewOnText }: ValueListEditorProps) => {
  const classNames = getClassNames()
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [newValues, setNewValues] = useState<Record<FieldKey, Value>>(values ?? {})
  const [validationState, setValidationState] = useState<Record<FieldKey, boolean>>({});
  const measurementConversionContextState = useMeasurementConversionContextState(channel?.fields);

  const onValueUpdate = (field: Field, value: Value): void => {
    let newValuesState = { ...newValues }
    if (notEmptyValue(value)) {
      newValuesState[field.name] = value
    } else {
      delete newValuesState[field.name]
    }
    setNewValues(newValuesState)
  }

  const onValueValidation = (field: Field, isValid: boolean): void => {
    if (validationState[field.name] !== isValid) {
      let newValidationState = { ...validationState }
      newValidationState[field.name] = isValid
      setValidationState(newValidationState)
    }
  }

  const onSaveValues = (): void => {
    const valuesToSave: Record<FieldKey, Value> = {};
    channel.fields.forEach(field => {
      const value = newValues[field.name];
      if (value !== undefined && notEmptyValue(value)) {
        valuesToSave[field.name] = value;
      }
    });
    if(showPreview) {
      setShowPreview(false);
    }
    onSave(valuesToSave);
  }

  return (
    <Stack>
      {channel.fields.map((field, index) => {
        return <ValueEditorComponent
          channelId={channel.channelId}
          key={index}
          field={field}
          value={values?.[field.name]}
          onUpdate={onValueUpdate}
          onValidation={onValueValidation}
        />
      })}
      <Separator />
      <Stack verticalAlign='center' horizontal>
        <PrimaryButton
          className={classNames.actionButton}
          onClick={onSaveValues}
          disabled={disabled || Object.values(validationState).some(x => !x)}
          text={onSaveText}
        />
        {onDelete && onDeleteText && (
          <PrimaryButton
            className={classNames.actionButton}
            onClick={onDelete}
            disabled={disabled}
            text={onDeleteText}
          />
        )}
        <Toggle
          className={classNames.toggle}
          disabled={disabled}
          onText={previewOnText}
          offText={previewOffText}
          role='checkbox'
          defaultChecked={showPreview}
          checked={showPreview}
          onChange={(_, checked) => setShowPreview(!!checked)}
        />
      </Stack>
      {showPreview && (
        <>
          <Separator />
          <MeasurementConversionContext.Provider value={measurementConversionContextState}>
            <PostContentContext.Provider value={{ channelId: channel.channelId }}>
              <PostCard
                channel={channel}
                post={{ values: newValues, channelId: channel.channelId, postId: '' }}
                enableManagement={false}
                navigate={() => { }}
              />
            </PostContentContext.Provider>
          </MeasurementConversionContext.Provider>
        </>
      )}
    </Stack>
  )
}
