import { Dropdown, IDropdownOption, mergeStyleSets } from '@fluentui/react'
import { MeasurementSettings, MeasurementSystem, Unit } from '@notidar/api'
import { useCallback, useMemo } from 'react'
import { ApiClient } from '../../../../api'
import { useAppDispatch } from '../../../../redux/hooks'
import { useTranslation } from 'react-i18next'
import { updateSettingsMeasurement } from '../../../../redux/features/userSlice'

const defaultSystem = 'default';
const defaultTemperatureUnit = Unit.TemperatureKelvin;

const systemOptions = [
  { key: defaultSystem, text: 'Use channel default', translationKey: 'content.fields.measurement.system_name.default' },
  { key: MeasurementSystem.Custom, text: 'Custom', translationKey: 'content.fields.measurement.system_name.custom' },
  { key: MeasurementSystem.Metric, text: 'Metric', translationKey: 'content.fields.measurement.system_name.metric' },
  { key: MeasurementSystem.Imperial, text: 'Imperial', translationKey: 'content.fields.measurement.system_name.imperial' },
]

export const useLocalizedSystemOptions = () => {
  const { t } = useTranslation();
  const localizedFields = useMemo(() => systemOptions.map(({ key, text, translationKey }) => ({ key, text: t(translationKey) ?? text })), [t]);
  return localizedFields;
}

const temperatureOptions = [
  { key: Unit.TemperatureCelsius, text: 'Celsius', translationKey: 'content.fields.measurement.unit_name.temperature_celsius' },
  { key: Unit.TemperatureFahrenheit, text: 'Fahrenheit', translationKey: 'content.fields.measurement.unit_name.temperature_fahrenheit' },
  { key: Unit.TemperatureKelvin, text: 'Kelvin', translationKey: 'content.fields.measurement.unit_name.temperature_kelvin' },
]

export const useLocalizedTemperatureOptions = () => {
  const { t } = useTranslation();
  const localizedFields = useMemo(() => temperatureOptions.map(({ key, text, translationKey }) => ({ key, text: t(translationKey) ?? text })), [t]);
  return localizedFields;
}

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
  })
}

export interface MeasurementsSettingsProps {
  settings?: MeasurementSettings
}

export const MeasurementsSettings: React.FC<MeasurementsSettingsProps> = ({ settings }: MeasurementsSettingsProps) => {
  const classNames = getClassNames()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const allowedSystemOptions = useLocalizedSystemOptions();
  const allowedSystemOptionsWithoutCustom = allowedSystemOptions.filter(x => x.key !== MeasurementSystem.Custom);
  const allowedTemperatureOptions = useLocalizedTemperatureOptions();

  const selectedSystem = settings?.system ?? defaultSystem;

  const onUpdate = useCallback(async (newSettings: MeasurementSettings) => {
    dispatch(updateSettingsMeasurement(newSettings));
    try {
      await ApiClient.updateUserMeasurementSettingsAsync({ measurementSettings: newSettings });
    } catch {
      // ignore
    }
  }, [dispatch]);

  const onMainSystemChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
      const newSystem = option?.key as MeasurementSystem;
      if (settings?.system !== newSystem) {
        const newSettings = { ...settings, system: MeasurementSystem[newSystem] };
        onUpdate(newSettings);
      }
    },
    [settings, onUpdate],
  )

  const onLengthSystemChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
      const newSystem = option?.key as MeasurementSystem;
      if (settings?.length?.system !== newSystem) {
        const newSettings = { ...settings, length: { system: MeasurementSystem[newSystem] } };
        onUpdate(newSettings);
      }
    },
    [settings, onUpdate],
  )

  const onAreaSystemChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
      const newSystem = option?.key as MeasurementSystem;
      if (settings?.area?.system !== newSystem) {
        const newSettings = { ...settings, area: { system: MeasurementSystem[newSystem] } };
        onUpdate(newSettings);
      }
    },
    [settings, onUpdate],
  )

  const onTemperatureSystemChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
      const newSystem = option?.key as MeasurementSystem;
      if (settings?.temperature?.system !== newSystem) {
        const temperatureUnit = settings?.temperature?.temperatureUnit ?? defaultTemperatureUnit;
        const newSettings = { ...settings, temperature: { system: MeasurementSystem[newSystem], temperatureUnit: temperatureUnit } };
        onUpdate(newSettings);
      }
    },
    [settings, onUpdate],
  )

  const onTemperatureUnitChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
      const newUnit = option?.key as Unit;
      if (settings?.temperature?.temperatureUnit !== newUnit) {
        const newSettings = { ...settings, temperature: { ...settings?.temperature, temperatureUnit: newUnit } };
        onUpdate(newSettings);
      }
    },
    [settings, onUpdate],
  )

  return (
    <div className={classNames.container}>
      <Dropdown
        label={t('pages.settings.measurement_system_label')}
        selectedKey={selectedSystem}
        onChange={onMainSystemChange}
        options={allowedSystemOptions}
      />
      {selectedSystem === MeasurementSystem.Custom &&
        <Dropdown
          label={t('pages.settings.measurement_length_system_label')}
          selectedKey={settings?.length?.system ?? defaultSystem}
          onChange={onLengthSystemChange}
          options={allowedSystemOptionsWithoutCustom}
        />
      }
      {selectedSystem === MeasurementSystem.Custom &&
        <Dropdown
          label={t('pages.settings.measurement_area_system_label')}
          selectedKey={settings?.area?.system ?? defaultSystem}
          onChange={onAreaSystemChange}
          options={allowedSystemOptionsWithoutCustom}
        />
      }
      {selectedSystem === MeasurementSystem.Custom &&
        <Dropdown
          label={t('pages.settings.measurement_temperature_system_label')}
          selectedKey={settings?.temperature?.system ?? defaultSystem}
          onChange={onTemperatureSystemChange}
          options={allowedSystemOptions}
        />
      }
      {selectedSystem === MeasurementSystem.Custom && settings?.temperature?.system === MeasurementSystem.Custom &&
        <Dropdown
          label={t('pages.settings.measurement_temperature_unit_label')}
          selectedKey={settings?.temperature?.temperatureUnit ?? defaultTemperatureUnit}
          onChange={onTemperatureUnitChange}
          options={allowedTemperatureOptions}
        />
      }
    </div>
  )
}
