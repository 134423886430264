import { FontIcon, Stack, mergeStyleSets, IconButton, IButtonStyles } from '@fluentui/react'
import { FontSizes } from '@fluentui/theme'
import { useAppDispatch } from '../../../redux/hooks'
import { hideLeftSideBar } from '../../../redux/features/appSlice'
import { useMenuOnClickNavigate } from '../../../hooks'

const getClassNames = () => {
  return mergeStyleSets({
    header: {
      fontSize: FontSizes.xLargePlus,
      fontWeight: 600,
      padding: '0 10px',
      cursor: 'pointer',
      userSelect: 'none',
    },
    container: {
      height: '100%',
    },
    logo: {
      height: 40,
      width: 40,
      margin: '10px 0px 10px 10px',
      cursor: 'pointer',
    },
    icon: {
      width: 60,
      height: 60,
    },
  })
}

const iconStyles: IButtonStyles = {
  icon: {
    width: 20,
    height: 20,
    fontSize: 20,
    lineHeight: 20,
  },
}

export const Header = () => {
  const classNames = getClassNames()
  const dispatch = useAppDispatch()
  const menuOnClickNavigate = useMenuOnClickNavigate()

  const onHideLeftSideBar = () => {
    dispatch(hideLeftSideBar())
  }

  return (
    <Stack
      horizontal
      horizontalAlign='space-between'
      verticalAlign='center'
      className={classNames.container}
    >
      <Stack
        horizontal
        horizontalAlign='start'
        verticalAlign='center'
        className={classNames.container}
      >
        <FontIcon
          aria-label='Notidar'
          iconName='custom_notidar'
          className={classNames.logo}
          onClick={menuOnClickNavigate('/')}
        />
        <h1 className={classNames.header} onClick={menuOnClickNavigate('/')}>
          Notidar
        </h1>
      </Stack>
      <IconButton
        onClick={onHideLeftSideBar}
        styles={iconStyles}
        className={classNames.icon}
        iconProps={{ iconName: 'ChevronLeft' }}
      />
    </Stack>
  )
}
