import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Label,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react'
import { ActionsPostSectionItem, Field, FieldType } from '@notidar/api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface CreateActionItemFormProps {
  fields: Field[]
  onAdd: (item: ActionsPostSectionItem) => void
}

export const CreateActionItemForm = ({ fields, onAdd }: CreateActionItemFormProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState<Partial<ActionsPostSectionItem>>({})
  const [validationState, setValidationState] = useState<{
    isLabelValid: boolean
    isFieldValid: boolean
  }>({ isLabelValid: false, isFieldValid: false })

  const allowedActionFields = fields
    .filter(x => x.type === FieldType.Link || x.type === FieldType.LinkList)
    .map(x => ({ key: x.name, text: x.displayName ? `${x.displayName} (${x.name})` : x.name }))

  const onClick = (): void => {
    setValidationState({ isFieldValid: false, isLabelValid: false })
    setState({})
    onAdd(state as ActionsPostSectionItem)
  }

  const onLinkChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    setState(x => ({ ...x, actionField: option?.key as string }))
    setValidationState(x => ({ ...x, isFieldValid: true }))
  }

  const onLinkClear = (): void => {
    setState(x => ({ ...x, actionField: undefined }))
    setValidationState(x => ({ ...x, isFieldValid: false }))
  }

  const labelChanged = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setValidationState(x => ({ ...x, isLabelValid: false }))
    setState(x => ({ ...x, displayString: newValue }))
  }

  const validateLabel = (value: string): string => {
    if (value.length === 0) {
      return ''
    }

    if (value.length > 32) {
      return t("content.sections.actions.actions_label_too_long");
    }

    setValidationState(x => ({ ...x, isLabelValid: true }))
    return ''
  }

  return (
    <>
      <Label>{t("content.sections.actions.actions_add_title")}</Label>
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack wrap tokens={{ childrenGap: 10 }} horizontalAlign={'stretch'}>
          <Stack.Item grow>
            <TextField
              label={t("content.sections.actions.actions_label_label")}
              value={state.displayString ?? ''}
              placeholder={t("content.sections.actions.actions_label_placeholder")} //
              validateOnLoad={false}
              onChange={labelChanged}
              deferredValidationTime={500}
              onGetErrorMessage={validateLabel}
            />
          </Stack.Item>
          <Stack.Item grow>
            <Dropdown
              label={t("content.sections.shared.select_field_label")}
              key={state.actionField}
              disabled={allowedActionFields.length === 0}
              onChange={onLinkChange}
              selectedKey={state.actionField}
              placeholder={
                allowedActionFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field_placeholder")
              }
              options={allowedActionFields}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal wrap tokens={{ childrenGap: 10 }} horizontalAlign={'stretch'}>
          <Stack.Item>
            <PrimaryButton
              disabled={!validationState.isFieldValid || !validationState.isLabelValid}
              onClick={onClick}
              text={t("content.sections.actions.actions_add")}
            />
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              disabled={!state.actionField}
              onClick={onLinkClear}
              text={t("content.sections.shared.clear")}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  )
}
