import { FontSizes, TooltipDelay, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { EnumField, EnumValue } from '@notidar/api'

export interface EnumValueComponentProps {
  field: EnumField
  value: EnumValue
}

export const EnumValueComponent = ({ field, value }: EnumValueComponentProps): JSX.Element | null => {
  const enumId = value.enumPayload?.enumId;
  if (enumId === undefined) {
    return null;
  }

  const enumValue = field.possibleValues.find(x => x.enumId === enumId);
  const displayValue = enumValue?.displayString;
  if (displayValue === undefined) {
    return null;
  }

  const onRenderContent = () => (
    <ul style={{ margin: 5, padding: 0, fontSize: FontSizes.large, }}>
      <li>{displayValue}</li>
    </ul>
  );

  return (
    <TooltipHost
      tooltipProps={{ onRenderContent }}
      delay={TooltipDelay.zero}
      overflowMode={TooltipOverflowMode.Parent}
    >
      <span>
        {displayValue}
      </span>
    </TooltipHost>
  )
}
