import React, { useEffect } from 'react'
import { Stack, mergeStyleSets, ITheme, useTheme } from '@fluentui/react'
import { Content } from './Content'
import { selectAppState, hideSideBars } from '../../redux/features/appSlice'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { Header } from './Header'
import { LeftSideBar } from './LeftSideBar'
import { RightSideBar } from './RightSideBar'

const getClassNames = (theme: ITheme, isMobile: boolean) => {
  return mergeStyleSets({
    app: {
      height: '100%',
    },
    leftSideBar: {
      height: '100%',
      position: 'relative',
      left: 0,
      background: theme.palette.white,
    },
    rightSideBar: {
      height: '100%',
      position: 'relative',
      right: 0,
      background: theme.palette.white,
    },
    contentContainer: {
      position: 'relative',
      height: '100%',
      width: isMobile ? '100vw' : 'auto',
      overflow: 'hidden',
      background: theme.palette.white,
    },
    contentBodyContainer: {
      height: '100%',
      width: isMobile ? '100vw' : 'auto',
    },
    contentHeaderContainer: {
      position: 'sticky',
      top: 0,
      height: '60px',
      borderWidth: '0px 0px 1px 0px',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      backgroundColor: theme.palette.white,
    },
    grayBlock: {
      paddingTop: 61,
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,0.8)',
      zIndex: 42,
    },
  })
}

export const App: React.FunctionComponent = () => {
  useEffect(() => {
    document.getElementById("animated_logo")?.remove();
  }, []); // remove splash screen logo once app is loaded
  
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const appState = useAppSelector(selectAppState)
  const classNames = getClassNames(theme, appState.screenType === 'mobile')

  const isGrayBlockVisible =
    appState.screenType === 'mobile' && (appState.isLeftSideBarVisible || appState.rightSideBarState)

  const onBodyClick = isGrayBlockVisible
    ? () => {
      dispatch(hideSideBars())
    }
    : undefined

  return (
    <Stack className={classNames.app} horizontal>
      {appState.isLeftSideBarVisible && (
        <Stack.Item disableShrink className={classNames.leftSideBar}>
          <LeftSideBar />
        </Stack.Item>
      )}
      <Stack.Item grow className={classNames.contentContainer}>
        <div className={classNames.contentBodyContainer}>
          {isGrayBlockVisible && <div className={classNames.grayBlock} onClick={onBodyClick}></div>}
          <div className={classNames.contentHeaderContainer}>
            <Header />
          </div>
          <Content />
        </div>
      </Stack.Item>
      <Stack.Item disableShrink className={classNames.rightSideBar}>
        <RightSideBar />
      </Stack.Item>
    </Stack>
  )
}
