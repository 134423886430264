import { CurrencyCode, MoneyField, MoneyValue } from '@notidar/api'
import { useContext } from 'react'
import { CurrencyConversionContext } from './CurrencyConversionContext'
import { FontSizes, mergeStyleSets, TooltipDelay, TooltipHost } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    converted: {
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
    },
  })
}

export interface CurrencyData {
  code: CurrencyCode
  symbol: string
  subunitSymbol: string
  exponent: number
}

export const currencyDataMap: Record<CurrencyCode, CurrencyData> = {
  [CurrencyCode.USD]: { code: CurrencyCode.USD, symbol: '$', subunitSymbol: '¢', exponent: 2 },
  [CurrencyCode.EUR]: { code: CurrencyCode.EUR, symbol: '€', subunitSymbol: '¢', exponent: 2 },
  [CurrencyCode.PLN]: { code: CurrencyCode.PLN, symbol: 'zł', subunitSymbol: 'gr', exponent: 2 },
  [CurrencyCode.RUB]: { code: CurrencyCode.RUB, symbol: '₽', subunitSymbol: 'коп', exponent: 2 },
  [CurrencyCode.CAD]: { code: CurrencyCode.CAD, symbol: '$', subunitSymbol: '¢', exponent: 2 },
  [CurrencyCode.NOK]: { code: CurrencyCode.NOK, symbol: 'kr', subunitSymbol: 'øre', exponent: 2 },
}

export interface MoneyValueComponentProps {
  field: MoneyField
  value: MoneyValue
}

export const MoneyValueComponent = ({ field, value }: MoneyValueComponentProps): JSX.Element | null => {
  const classNames = getClassNames();
  const { t } = useTranslation();
  const { targetCurrency, convertMoneyFrom, convertMoneyTo } = useContext(CurrencyConversionContext);
  const { symbol, exponent } = currencyDataMap[field.currency]

  const moneyValue = value.moneyPayload?.value;
  if (moneyValue === undefined || moneyValue === null) {
    return null
  }

  if (targetCurrency != field.currency && targetCurrency && convertMoneyFrom && convertMoneyTo) {
    const convertedValue = convertMoneyFrom(field.currency, moneyValue)
    const { symbol: targetSymbol, exponent: targetExponent } = currencyDataMap[targetCurrency]

    const onRenderContent = () => (
      <ul style={{ margin: 5, padding: 0, fontSize: FontSizes.large,}}>
        <li>{t("content.fields.money.original_value", { value: `${moneyValue / 10 ** exponent}${symbol}` })}</li>
        <li>{t("content.fields.money.conversion_rate", { value: `1${targetSymbol} ~ ${convertMoneyTo(field.currency, 1).toFixed(2)}${symbol}` })}</li>
      </ul>
    );

    return (
      <TooltipHost
        tooltipProps={{ onRenderContent }}
        delay={TooltipDelay.zero}
      >
        <span className={classNames.converted}>
          {Math.trunc(convertedValue) / 10 ** targetExponent}{targetSymbol}
        </span>
      </TooltipHost>
    )
  }

  return (
    <span>
      {moneyValue / 10 ** exponent}{symbol}
    </span>
  )
}
