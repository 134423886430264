import { MeasurementField, MeasurementValue, Unit } from '@notidar/api'
import { useContext } from 'react'
import { MeasurementConversionContext } from './MeasurementConversionContext'
import { FontSizes, mergeStyleSets, TooltipDelay, TooltipHost } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { toMeasurementString } from './utils'

const getClassNames = () => {
  return mergeStyleSets({
    converted: {
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
    },
  })
}

export interface UnitData {
  unit: Unit
  symbol: string
  subunitSymbol?: string
  subunitDivider?: number,
  assumeLeftPart?: boolean, // if single component is provided, assume it's the left part. default is false
}

export const unitDataMap: Record<Unit, UnitData> = {
  [Unit.TemperatureCelsius]: { unit: Unit.TemperatureCelsius, symbol: '°C' },
  [Unit.TemperatureFahrenheit]: { unit: Unit.TemperatureFahrenheit, symbol: '°F' },
  [Unit.TemperatureKelvin]: { unit: Unit.TemperatureKelvin, symbol: 'K' },
  [Unit.LengthMeter]: { unit: Unit.LengthMeter, symbol: 'm' },
  [Unit.LengthFoot]: { unit: Unit.LengthFoot, symbol: 'ft' },
  [Unit.LengthMeterAndCentimeter]: { unit: Unit.LengthMeterAndCentimeter, symbol: 'm', subunitSymbol: 'cm', subunitDivider: 100 },
  [Unit.LengthFootAndInch]: { unit: Unit.LengthFootAndInch, symbol: 'ft', subunitSymbol: 'in', subunitDivider: 12, assumeLeftPart: true },
  [Unit.LengthCentimeter]: { unit: Unit.LengthCentimeter, symbol: 'cm' },
  [Unit.LengthInch]: { unit: Unit.LengthInch, symbol: 'in' },
  [Unit.AreaSquareMeter]: { unit: Unit.AreaSquareMeter, symbol: 'm²' },
  [Unit.AreaSquareFoot]: { unit: Unit.AreaSquareFoot, symbol: 'ft²' },
}

export interface MeasurementValueComponentProps {
  field: MeasurementField
  value: MeasurementValue
}

export const MeasurementValueComponent = ({ field, value }: MeasurementValueComponentProps): JSX.Element | null => {
  const classNames = getClassNames();
  const { t } = useTranslation();
  const { getConverter } = useContext(MeasurementConversionContext);

  const measurementValue = value.measurementPayload?.value;
  if (measurementValue === undefined || measurementValue === null) {
    return null
  }

  const { symbol: originalSymbol, subunitSymbol: originalSubUnitSymbol, subunitDivider: originalSubUnitDivider } = unitDataMap[field.unit];
  const converter = getConverter?.(field.name);
  if (converter !== undefined) {
    const convertedValue = converter.convert(measurementValue);
    const { symbol: convertedSymbol, subunitSymbol: convertedSubUnitSymbol, subunitDivider: convertedSubUnitDivider } = unitDataMap[converter.targetUnit];
    const onRenderContent = () => (
      <ul style={{ margin: 5, padding: 0, fontSize: FontSizes.large, }}>
        <li>{t("content.fields.measurement.original_value", { value: `${toMeasurementString(measurementValue, originalSymbol, originalSubUnitDivider, originalSubUnitSymbol)}` })}</li>
      </ul>
    );

    return (
      <TooltipHost
        tooltipProps={{ onRenderContent }}
        delay={TooltipDelay.zero}
      >
        <span className={classNames.converted}>
          {toMeasurementString(Math.round(convertedValue), convertedSymbol, convertedSubUnitDivider, convertedSubUnitSymbol)}
        </span>
      </TooltipHost>
    )
  }
  else {
    return <span>{toMeasurementString(measurementValue, originalSymbol, originalSubUnitDivider, originalSubUnitSymbol)}</span>
  }
}
