import { mergeStyleSets, Spinner } from '@fluentui/react'
import { Card, CardList } from '../../App/Card'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'
import { selectUserChannelsState } from '../../../redux/features/userChannelsSlice'
import { useEffect, useState } from 'react'
import { ChannelIdentifier} from '@notidar/content'
import { ApiClient } from '../../../api'
import { SectionList } from './SectionList'
import { Channel } from '@notidar/api'
import { ChannelNameHeader } from '../../Shared/ChannelNameHeader'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
  })
}

export const ManageChannelSectionsView = () => {
  const classNames = getClassNames();
  const { t } = useTranslation();
  const { channelIdentifier } = useParams<{ channelIdentifier: ChannelIdentifier }>()
  const { channels: userChannels } = useAppSelector(selectUserChannelsState)

  const [state, setState] = useState<{ channel?: Channel | null; isLoading: boolean }>({
    isLoading: true,
    channel: undefined,
  })

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiClient.getChannelAsync(channelIdentifier!, { secure: true });
      setState({ channel: response.data.channel, isLoading: false })
    }

    fetchData().catch(() => {
      setState({ channel: null, isLoading: false })
    })
  }, [channelIdentifier])

  const isManagedChannel =
    state.channel && userChannels?.findIndex(x => x.channelId === state.channel!.channelId) !== -1

  return (
    <CardList>
      <Card>
        {state.channel === undefined && <div className={classNames.container}><Spinner label={t('shared.loading')} /></div>}
        {state.channel === null && <div>{t('pages.manage_sections.channel_not_found')}</div>}
        {state.channel && !isManagedChannel && <div>{t('pages.manage_sections.channel_not_allowed')}</div>}
        {state.channel && isManagedChannel && (
          <>
            <ChannelNameHeader name={t("pages.manage_sections.title", { channel: state.channel.name })} />
            <SectionList
              channelIdentifier={channelIdentifier!}
              existingSections={state.channel.postSections}
              fields={state.channel.fields}
            />
          </>
        )}
      </Card>
    </CardList>
  )
}
