
import {
  DateTimeField,
  EnumField,
  EnumListField,
  Field,
  FieldType,
  MeasurementField,
  MoneyField,
  NumberField
} from "@notidar/api"
import { StringFieldEditorComponent } from "./string"
import { AllFields } from "../utils"
import { NumberFieldEditorComponent } from "./number"
import { MoneyFieldEditorComponent } from "./money"
import { MeasurementFieldEditorComponent } from "./measurement"
import { DateTimeFieldEditorComponent } from "./datetime"
import { MarkdownFieldEditorComponent } from "./markdown"
import { LinkFieldEditorComponent } from "./link"
import { ImageFieldEditorComponent } from "./imageFile"
import { EnumFieldEditorComponent } from "./enum"

export interface FieldEditorComponentProps {
  field: AllFields,
  isHidden: boolean,
  onUpdate: (field: Field, isValid: boolean) => void,
}

export const FieldEditorComponent = ({ field, isHidden, onUpdate }: FieldEditorComponentProps): JSX.Element | null => {
  switch (field.type) {
    case FieldType.String:
      return <StringFieldEditorComponent field={field} onUpdate={onUpdate} hidden={isHidden} />
    case FieldType.Number:
      return <NumberFieldEditorComponent field={field as NumberField} onUpdate={onUpdate} hidden={isHidden} />
    case FieldType.Money:
      return <MoneyFieldEditorComponent field={field as MoneyField} onUpdate={onUpdate} hidden={isHidden} />
    case FieldType.Measurement:
      return <MeasurementFieldEditorComponent field={field as MeasurementField} onUpdate={onUpdate} hidden={isHidden} />
    case FieldType.DateTime:
      return <DateTimeFieldEditorComponent field={field as DateTimeField} onUpdate={onUpdate} hidden={isHidden} />
    case FieldType.Markdown:
      return <MarkdownFieldEditorComponent field={field} onUpdate={onUpdate} hidden={isHidden} />
    case FieldType.Link:
      return <LinkFieldEditorComponent field={field} onUpdate={onUpdate} hidden={isHidden} />
    case FieldType.ImageFileList:
      return <ImageFieldEditorComponent field={field} onUpdate={onUpdate} hidden={isHidden} />
    case FieldType.Enum:
    case FieldType.EnumList:
      return <EnumFieldEditorComponent field={field as EnumField | EnumListField} onUpdate={onUpdate} hidden={isHidden} />
    default:
      return null
  }
}