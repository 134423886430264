import { Stack, TextField } from '@fluentui/react'
import { FieldType } from '@notidar/api'
import { ValueEditorProps } from '../Shared.types'
import { ItemHeader } from '../shared/ItemHeader'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface MarkdownValueEditorProps extends ValueEditorProps<FieldType.Markdown> {}

export const MarkdownValueEditor = ({
  field,
  value,
  onUpdate,
  onValidation,
}: MarkdownValueEditorProps) => {
  const [newValue, setNewValue] = useState<string | undefined>(value?.markdownPayload?.value ?? undefined);
  const { t } = useTranslation();

  const onValueChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const result = newValue || ''
    setNewValue(result)
    onUpdate(field, { type: FieldType.Markdown, markdownPayload: { value: result } })
    onValidation(field, true)
  }

  return (
    <Stack>
      <ItemHeader name={field.name} title={field.displayName} />
      <TextField
        placeholder={t("content.fields.markdown.placeholder")}
        value={newValue}
        multiline
        rows={3}
        onChange={onValueChange}
      />
    </Stack>
  )
}
