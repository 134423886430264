import { Checkbox, Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react'
import { FieldType, MeasurementField, Unit } from '@notidar/api'
import { FieldEditorComponentProps } from '../Shared.types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const units = [
  { key: Unit.TemperatureCelsius, text: 'Celsius', translationKey: 'content.fields.measurement.unit_name.temperature_celsius' },
  { key: Unit.TemperatureFahrenheit, text: 'Fahrenheit', translationKey: 'content.fields.measurement.unit_name.temperature_fahrenheit' },
  { key: Unit.TemperatureKelvin, text: 'Kelvin', translationKey: 'content.fields.measurement.unit_name.temperature_kelvin' },
  { key: Unit.LengthMeter, text: 'Meter', translationKey: 'content.fields.measurement.unit_name.length_meter' },
  { key: Unit.LengthFoot, text: 'Foot', translationKey: 'content.fields.measurement.unit_name.length_foot' },
  { key: Unit.LengthMeterAndCentimeter, text: 'Metric and centimeter', translationKey: 'content.fields.measurement.unit_name.length_meter_and_centimeter' },
  { key: Unit.LengthFootAndInch, text: 'Foot and inch', translationKey: 'content.fields.measurement.unit_name.length_foot_and_inch' },
  { key: Unit.LengthCentimeter, text: 'Centimeter', translationKey: 'content.fields.measurement.unit_name.length_centimeter' },
  { key: Unit.LengthInch, text: 'Inch', translationKey: 'content.fields.measurement.unit_name.length_inch' },
  { key: Unit.AreaSquareMeter, text: 'Square meter', translationKey: 'content.fields.measurement.unit_name.area_square_meter' },
  { key: Unit.AreaSquareFoot, text: 'Square foot', translationKey: 'content.fields.measurement.unit_name.area_square_foot' },
]

const useLocalizedUnits = () => {
  const { t } = useTranslation();
  const localizedChannelStatuses = useMemo(() => units.map(({ key, text, translationKey }) => ({ key, text: t(translationKey) ?? text })), [t]);
  return localizedChannelStatuses;
}

export interface MeasurementFieldEditorComponentProps extends FieldEditorComponentProps<FieldType.Measurement> { }

export const MeasurementFieldEditorComponent = ({ field, hidden, onUpdate }: MeasurementFieldEditorComponentProps) => {
  const [fieldState, setFieldState] = useState<MeasurementField>(field);
  const { t } = useTranslation();
  const options = useLocalizedUnits();

  const isValid = (field: MeasurementField): boolean => {
    const isUnitValid = field.unit !== undefined
    return isUnitValid && validateTitle(field.displayName ?? '') === ''
  }

  const validateTitle = (value: string): string => {
    if (value.length === 0) {
      return ''
    }

    if (value.length > 32) {
      return t("content.fields.shared.displayName_too_long");
    }

    return ''
  }

  useEffect(() => {
    onUpdate(fieldState, isValid(fieldState))
  }, [fieldState]) // eslint-disable-line react-hooks/exhaustive-deps

  const titleValueChanged = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    const newField: MeasurementField = { ...fieldState, displayName: newValue }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  const currencyValueChanged = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    const newField: MeasurementField = {
      ...fieldState,
      unit: option?.key as Unit,
    }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  const searchableValueChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    const newField: MeasurementField = { ...fieldState, searchable: checked }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  if (hidden) {
    return null
  }

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <TextField
        label={t("content.fields.shared.displayName")}
        value={fieldState.displayName ?? ''}
        onChange={titleValueChanged}
        onGetErrorMessage={validateTitle}
        deferredValidationTime={500}
      />
      <Dropdown
        label={t("content.fields.measurement.unit")}
        placeholder={t("content.fields.measurement.pick_unit")}
        options={options}
        selectedKey={fieldState.unit}
        onChange={currencyValueChanged}
      />
      <Checkbox label={t("content.fields.shared.searchable")} checked={fieldState.searchable} onChange={searchableValueChanged} />
    </Stack>
  )
}
