import { ITheme, IconButton, Stack, Text, mergeStyleSets, useTheme } from '@fluentui/react'
import { useState } from 'react'
import {
  Field,
} from '@notidar/api'
import { useTranslation } from 'react-i18next'
import {
  useLocalizedFieldTypes,
  FieldEditorComponent,
} from '@notidar/content'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    header: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
    },
    headerText: {
      margin: '0 10px 0',
    },
    formContainer: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      padding: '0 10px 10px',
    },
  })
}

export interface FieldEditorComponentContainerProps {
  field: Field
  onUpdate: (field: Field, isValid: boolean) => void
  onRemove: (field: Field) => void
}

export const FieldEditorComponentContainer = ({ field, onUpdate, onRemove }: FieldEditorComponentContainerProps) => {
  const { t } = useTranslation();
  const theme = useTheme()
  const allowedFieldTypes = useLocalizedFieldTypes();
  const classNames = getClassNames(theme)
  const [state, setState] = useState<{
    name?: string | null
    title?: string | null
    isValid?: boolean
  }>({
    name: field.name,
    title: field.displayName,
  })
  const [isHidden, setIsHidden] = useState<boolean>(true)

  const onUpdateInternal = (field: Field, isValid: boolean): void => {
    setState({ isValid: isValid, name: field.name, title: field.displayName })
    if (isHidden && !isValid) {
      setIsHidden(false)
    }
    onUpdate(field, isValid)
  }

  const switchIsHidden = () => {
    setIsHidden(x => !x)
  }

  const remove = () => {
    onRemove(field)
  }

  return (
    <Stack>
      <Stack
        className={classNames.header}
        style={isHidden ? {} : { backgroundColor: theme.palette.neutralQuaternary }}
        horizontal
        verticalAlign='center'
        horizontalAlign='space-between'
      >
        <Stack horizontal verticalAlign='center'>
          <Text
            className={classNames.headerText}
            style={state.isValid === false ? { color: 'rgb(164, 38, 44)' } : {}}
            variant={'large'}
            nowrap
            block
          >
            {(state.title ? `${state.title} (${state.name})` : `${state.name}`) +
              ' - ' +
              allowedFieldTypes.find(x => x.key === field.type)?.text}
          </Text>
          <IconButton iconProps={{ iconName: 'Cancel' }} title={t("shared.remove")} onClick={remove} />
        </Stack>
        <IconButton
          iconProps={{ iconName: isHidden ? 'ChevronDown' : 'ChevronUp' }}
          title={isHidden ? t("shared.expand") : t("shared.collapse")}
          onClick={switchIsHidden}
        />
      </Stack>
      <div className={isHidden ? undefined : classNames.formContainer}>
        <FieldEditorComponent field={field} isHidden={isHidden} onUpdate={onUpdateInternal} />
      </div>
    </Stack>
  )
}
