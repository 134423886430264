import { Label, Link } from '@fluentui/react'
import { AuthorPostSection, DateTimeField, DateTimeValue, EnumField, EnumListField, EnumListValue, EnumValue, Field, FieldType, LinkValue, StringListValue, StringValue } from '@notidar/api'
import { SectionComponentProps } from '../SectionComponent'
import { SectionContainer } from '../SectionContainer'
import { parseFieldValue, getTypedValueFieldByFieldKey } from '../../utils'
import { notEmpty } from '@notidar/common'
import { useTranslation } from 'react-i18next'
import { DateTimeValueComponent } from '../../fields/datetime/DateTimeValueComponent'

export interface AuthorSectionComponentProps
  extends SectionComponentProps<AuthorPostSection> { }

export const AuthorSectionComponent = (props: AuthorSectionComponentProps): JSX.Element | null => {
  const { t, i18n } = useTranslation();
  const authorNames = parseFieldValue<FieldType.String | FieldType.StringList | FieldType.Enum | FieldType.EnumList, string[]>(
    props,
    props.section.authorField,
    {
      String: (value: StringValue) => { return value.stringPayload?.value ? [value.stringPayload?.value] : undefined },
      StringList: (value: StringListValue) => { return value.stringListPayload?.values ?? undefined },
      Enum: (value: EnumValue, field: Field) => {
        const map = (field as EnumField).possibleValues.reduce((x, tag) => {
          x[tag.enumId] = tag.displayString
          return x
        }, {} as Record<string, string>)
        const displayValue = value.enumPayload?.enumId ? map[value.enumPayload.enumId] : undefined
        return displayValue ? [displayValue] : []
      },
      EnumList: (value: EnumListValue, field: Field) => {
        const map = (field as EnumListField).possibleValues.reduce((x, tag) => {
          x[tag.enumId] = tag.displayString
          return x
        }, {} as Record<string, string>)
        return value.enumListPayload?.enumIds?.map(x => map[x]).filter(notEmpty) ?? []
      },
    }) ?? []

  const authorLink = parseFieldValue<FieldType.Link, string>(
    props,
    props.section.linkField,
    {
      Link: (value: LinkValue) => { return value.linkPayload?.link ?? undefined }
    })

  if (authorNames == undefined || authorNames.length === 0) {
    return null
  }

  const authorsString = authorNames.join(", ")
  const authorsElement = authorLink ? (
    <Link target='_blank' href={authorLink}>
      {authorsString}
    </Link>
  ) : (
    authorsString
  )

  const { field: datetimeField , value: datetimeValue } = getTypedValueFieldByFieldKey(props, props.section.dateTimeField) ?? {}

  let datetimeElement: JSX.Element | null = null;
  if(datetimeField !== undefined && datetimeValue !== undefined) {
    datetimeElement = <DateTimeValueComponent field={datetimeField as DateTimeField} value={datetimeValue as DateTimeValue}/>
  }

  return (
    <SectionContainer>
      <Label style={{ padding: 0 }}>
        <b>{authorsElement}</b>{datetimeElement ? ' - ' : null}{datetimeElement}
      </Label>
    </SectionContainer>
  )
}
