import { FieldKey, FieldTypeToField, FieldTypeToValue, FieldTypeUnion } from './models'
import {
  AuthorPostSection,
  BoolField,
  BoolValue,
  DateTimeField,
  DateTimeValue,
  EnumListField,
  EnumListValue,
  EnumField,
  EnumValue,
  Field,
  FieldType,
  LinkListField,
  LinkListValue,
  LinkField,
  LinkValue,
  MarkdownField,
  MarkdownPostSection,
  MarkdownValue,
  PostSection,
  PostSectionType,
  StringListField,
  StringListValue,
  StringField,
  StringValue,
  TagsPostSection,
  TitlePostSection,
  Value,
  ImageFileListField,
  ImageFileListValue,
  GalleryPostSection,
  ActionsPostSection,
  PropertiesPostSection,
  TitlePreviewSection,
  GalleryPreviewSection,
  PropertiesPreviewSection,
  PreviewSection,
  PreviewSectionType,
  NumberValue,
  MoneyValue,
  NumberField,
  MoneyField,
  FileType,
  EnumFilter,
  TextFilter,
  MeasurementField,
  MeasurementValue,
  LocationField,
  GeographyField,
  LocationValue,
  GeographyValue,
  MapPostSection,
} from '@notidar/api'

export interface SectionProps {
  fields: Field[]
  postValues: Record<FieldKey, Value>
  channelValues: Record<FieldKey, Value>
}

export function fileUrlFromId(
  mediaBaseUrl: string,
  fileId: string,
): string {
  return `${mediaBaseUrl}/files/${fileId}`
}

export function originalImageFileUrlFromId(
  mediaBaseUrl: string,
  fileId: string,
): string {
  return `${mediaBaseUrl}/files/${fileId}/original`
}

export function getFileType(type: string): FileType {
  switch (type) {
    case "image/jpeg":
    case "image/jpg":
      return FileType.JpegFile
    case "image/gif":
      return FileType.GifFile
    case "image/png":
      return FileType.PngFile
    default:
      return FileType.Unknown
  }
}

export function optimizedImageFileUrlFromId(
  mediaBaseUrl: string,
  fileId: string,
): string {
  return `${mediaBaseUrl}/files/${fileId}/optimized`
}

export function getFieldByFieldKey(
  { fields }: Pick<SectionProps, 'fields'>,
  fieldKey: FieldKey | undefined | null,
  types?: FieldType[],
): Field | undefined {
  if (!fieldKey) {
    return undefined
  }
  const field = fields.find(x => x.name === fieldKey)
  if (!field) {
    return undefined
  }
  if (types?.includes(field.type) ?? true) {
    return field
  }
}

export function getValueByFieldKey(
  { postValues, channelValues }: SectionProps,
  fieldKey: FieldKey | undefined | null,
  field: Field,
): Value | undefined {
  if (!fieldKey) {
    return undefined
  }
  for (let values of [postValues, channelValues]) {
    let content = values[fieldKey]
    if (content !== undefined && content.type === field.type) {
      return content
    }
  }
}

export function getTypedValueByFieldKey(
  props: SectionProps,
  fieldKey: FieldKey | undefined | null,
  types?: FieldType[],
): AllValues | undefined {
  if (!fieldKey) {
    return undefined
  }
  const field = getFieldByFieldKey(props, fieldKey, types)
  if (!field) {
    return undefined
  }

  const value = getValueByFieldKey(props, fieldKey, field)
  if (!value) {
    return undefined
  }

  return mapValue(value)
}

export function getTypedValueFieldByFieldKey(
  props: SectionProps,
  fieldKey: FieldKey | undefined | null,
  types?: FieldType[],
): { field: AllFields; value: AllValues } | undefined {

  if (!fieldKey) {
    return undefined
  }
  const field = getFieldByFieldKey(props, fieldKey, types)
  if (!field) {
    return undefined
  }

  const value = getValueByFieldKey(props, fieldKey, field)
  if (!value) {
    return undefined
  }
  if (field.type !== value.type) {
    return undefined
  }
  const fieldMapped = mapField(field)
  const valueMapped = mapValue(value)
  if (!fieldMapped || !valueMapped) {
    return undefined
  }

  return { field: fieldMapped, value: valueMapped }
}

type FieldTransformRecords<TFieldType extends FieldTypeUnion, TResult> = {
  [P in TFieldType]: (value: Value, field: Field) => TResult | undefined;
};

export function parseFieldValue<TFieldType extends FieldTypeUnion, TResult>(
  props: SectionProps,
  fieldKey: FieldKey | undefined | null,
  map: FieldTransformRecords<TFieldType, TResult>): TResult | undefined {

  if (!fieldKey) {
    return undefined
  }
  const field = getFieldByFieldKey(props, fieldKey)
  if (!field) {
    return undefined
  }

  const value = getValueByFieldKey(props, fieldKey, field)
  if (!value) {
    return undefined
  }

  if (value.type !== field.type) {
    return undefined
  }

  const fieldMapped = mapField(field)
  const valueMapped = mapValue(value)
  if (!fieldMapped || !valueMapped) {
    return undefined
  }

  return map[field.type as TFieldType]?.(value as FieldTypeToValue<TFieldType>, field as FieldTypeToField<TFieldType>)
}

export function mapField(field: Field): AllFields | undefined {
  switch (field.type) {
    case FieldType.Bool:
      return field as BoolField
    case FieldType.DateTime:
      return field as DateTimeField
    case FieldType.Enum:
      return field as EnumField
    case FieldType.EnumList:
      return field as EnumListField
    case FieldType.ImageFileList:
      return field as ImageFileListField
    case FieldType.Link:
      return field as LinkField
    case FieldType.LinkList:
      return field as LinkListField
    case FieldType.Markdown:
      return field as MarkdownField
    case FieldType.String:
      return field as StringField
    case FieldType.StringList:
      return field as StringListField
    case FieldType.Number:
      return field as NumberField
    case FieldType.Money:
      return field as MoneyField
    case FieldType.Measurement:
      return field as MeasurementField
    case FieldType.Location:
      return field as LocationField
    case FieldType.Geography:
      return field as GeographyField
    default:
      return undefined
  }
}

export type AllFields =
  | BoolField
  | DateTimeField
  | EnumField
  | EnumListField
  | ImageFileListField
  | LinkField
  | LinkListField
  | MarkdownField
  | StringField
  | StringListField
  | NumberField
  | MoneyField
  | MeasurementField
  | LocationField
  | GeographyField

export type AllFilters =
  | EnumFilter
  | TextFilter

export function mapValue(value: Value): AllValues | undefined {
  switch (value.type) {
    case FieldType.Bool:
      return value as BoolValue
    case FieldType.DateTime:
      return value as DateTimeValue
    case FieldType.Enum:
      return value as EnumValue
    case FieldType.EnumList:
      return value as EnumListValue
    case FieldType.ImageFileList:
      return value as ImageFileListValue
    case FieldType.Link:
      return value as LinkValue
    case FieldType.LinkList:
      return value as LinkListValue
    case FieldType.Markdown:
      return value as MarkdownValue
    case FieldType.String:
      return value as StringValue
    case FieldType.StringList:
      return value as StringListValue
    case FieldType.Number:
      return value as NumberValue
    case FieldType.Money:
      return value as MoneyValue
    case FieldType.Measurement:
      return value as MeasurementValue
    case FieldType.Location:
      return value as LocationValue
    case FieldType.Geography:
      return value as GeographyValue
    default:
      return undefined
  }
}

export type AllValues =
  | BoolValue
  | DateTimeValue
  | EnumValue
  | EnumListValue
  | ImageFileListValue
  | LinkValue
  | LinkListValue
  | MarkdownValue
  | StringValue
  | StringListValue
  | NumberValue
  | MoneyValue
  | MeasurementValue
  | LocationValue
  | GeographyValue

export function mapSection(section: PostSection): AllPostSections | undefined {
  switch (section.type) {
    case PostSectionType.Author:
      return section as AuthorPostSection
    case PostSectionType.Gallery:
      return section as GalleryPostSection
    case PostSectionType.Actions:
      return section as ActionsPostSection
    case PostSectionType.Markdown:
      return section as MarkdownPostSection
    case PostSectionType.Tags:
      return section as TagsPostSection
    case PostSectionType.Title:
      return section as TitlePostSection
    case PostSectionType.Properties:
      return section as PropertiesPostSection
    case PostSectionType.Map:
      return section as MapPostSection
    default:
      return undefined
  }
}

export type AllPostSections =
  | AuthorPostSection
  | GalleryPostSection
  | ActionsPostSection
  | MarkdownPostSection
  | TagsPostSection
  | TitlePostSection
  | PropertiesPostSection
  | MapPostSection

export function mapPreviewSection(section: PreviewSection): AllPreviewSections | undefined {
  switch (section.type) {
    case PreviewSectionType.Title:
      return section as TitlePreviewSection
    case PreviewSectionType.Gallery:
      return section as GalleryPreviewSection
    case PreviewSectionType.Properties:
      return section as PropertiesPreviewSection
    default:
      return undefined
  }
}

export type AllPreviewSections = TitlePreviewSection | GalleryPreviewSection | PropertiesPreviewSection