import { MeasurementSettings, Unit, MeasurementField, MeasurementSystem } from '@notidar/api';
import { createContext } from 'react';
import { Converter, temperatureConversionMap, toImperialConversionMap, toMetricConversionMap } from './converters';

export const createMeasurementConversionContextState = (measurementSettings: MeasurementSettings, fields: MeasurementField[]): MeasurementConversionContextState => {
  const globalSystem = measurementSettings.system;
  if (globalSystem === undefined) {
    return {};
  }

  const fieldMap = new Map<string, Converter>();
  fields.forEach(field => {
    if (field.unit.startsWith("Temperature")) {
      const temperatureSystem = globalSystem === MeasurementSystem.Custom ? measurementSettings.temperature?.system : globalSystem;
      switch (temperatureSystem) {
        case MeasurementSystem.Metric:
          const toMetricConverter = toMetricConversionMap.get(field.unit);
          if (toMetricConverter) {
            fieldMap.set(field.name, toMetricConverter);
          }
          break;
        case MeasurementSystem.Imperial:
          const toImperialConverter = toImperialConversionMap.get(field.unit);
          if (toImperialConverter) {
            fieldMap.set(field.name, toImperialConverter);
          }
          break;
        case MeasurementSystem.Custom:
          const targetUnit = measurementSettings.temperature?.temperatureUnit as Unit;
          const toTargetUnitConverter = temperatureConversionMap.get(targetUnit)?.get(field.unit);
          if (toTargetUnitConverter) {
            fieldMap.set(field.name, toTargetUnitConverter);
          }
          break;
      }
    } else if (field.unit.startsWith("Length")) {
      const lengthSystem = globalSystem === MeasurementSystem.Custom ? measurementSettings.length?.system : globalSystem;
      switch (lengthSystem) {
        case MeasurementSystem.Metric:
          const toMetricConverter = toMetricConversionMap.get(field.unit);
          if (toMetricConverter) {
            fieldMap.set(field.name, toMetricConverter);
          }
          break;
        case MeasurementSystem.Imperial:
          const toImperialConverter = toImperialConversionMap.get(field.unit);
          if (toImperialConverter) {
            fieldMap.set(field.name, toImperialConverter);
          }
          break;
      }
    } else if (field.unit.startsWith("Area")) {
      const areaSystem = globalSystem === MeasurementSystem.Custom ? measurementSettings.area?.system : globalSystem;
      switch (areaSystem) {
        case MeasurementSystem.Metric:
          const toMetricConverter = toMetricConversionMap.get(field.unit);
          if (toMetricConverter) {
            fieldMap.set(field.name, toMetricConverter);
          }
          break;
        case MeasurementSystem.Imperial:
          const toImperialConverter = toImperialConversionMap.get(field.unit);
          if (toImperialConverter) {
            fieldMap.set(field.name, toImperialConverter);
          }
          break;
      }
    }
  });

  return {
    getConverter: (fieldName: string): Converter | undefined => {
      return fieldMap.get(fieldName);
    }
  };
}

export type MeasurementConversionContextState = {
  getConverter?: (fieldName: string) => Converter | undefined;
};

export const MeasurementConversionContext = createContext<MeasurementConversionContextState>({
});