import { Card, CardList } from '../../App/Card'
import { mergeStyleSets } from '@fluentui/react'
import { useState } from 'react'
import { SearchPanel } from './SearchPanel/SearchPanel'
import { RssConnection } from './RssConnection/RssConnection'
import { ChannelSearch } from './ChannelSearch/ChannelSearch'

type ChannelSearchViewState = {
  target: 'rss' | 'search'
  tempTerm: string
  term: string
}

const getClassNames = () => {
  return mergeStyleSets({
    header: {
      margin: 10,
    },
    body: {
      margin: 10,
    },
  })
}

export const ChannelSearchView = () => {
  const classNames = getClassNames()
  const [state, setState] = useState<ChannelSearchViewState>({ target: 'search', tempTerm: '', term: '' })

  return (
    <CardList>
      <Card>
        <div className={classNames.header}>
          <SearchPanel
            term={state.tempTerm}
            target={state.target}
            onTargetChange={target => setState(x => ({ ...x, target, term: '', tempTerm: '' }))}
            onTermChange={tempTerm => setState(x => ({ ...x, tempTerm }))}
            onTermSubmit={term => setState(x => ({ ...x, term }))}
          />
        </div>
      </Card>
      <Card>
        <div className={classNames.body}>
          {state.target === 'search' && <ChannelSearch term={state.term} />}
          {state.target === 'rss' && <RssConnection rssLink={state.term} />}
        </div>
      </Card>
    </CardList>
  )
}
