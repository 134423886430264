import { MessageBar, MessageBarType, Stack, mergeStyleSets } from '@fluentui/react'
import { SubscriptionNotificationEditComponent } from './SubscriptionNotificationEditComponent'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { selectSubscriptionsState, updateSubscriptionNotificationLevel } from '../../redux/features/subscriptionsSlice'
import { selectPostsState } from '../../redux/features/postsSlice'
import { useTranslation } from 'react-i18next'
import { NotificationLevel } from '@notidar/api'
import { useState } from 'react'

const getClassNames = () => {
  return mergeStyleSets({
    sidebarContainer: {
      height: '100%',
      padding: '10px',
    },
  })
}

export const ChannelNotifications = () => {
  const classNames = getClassNames()
  const { t } = useTranslation()
  const { subscriptions, actionId, actionStatus, actionErrorCode, actionSubscriptionId } = useAppSelector(selectSubscriptionsState)
  const { subscriptionId } = useAppSelector(selectPostsState);
  const dispatch = useAppDispatch();
  const [subscriptionActionId, setSubscriptionActionId] = useState<number>();

  const subscription = subscriptions?.find(s => s.subscriptionId === subscriptionId);

  const updateNotificationLevel = (notificationLevel: NotificationLevel) => {
    if (subscription) {
      const newActionId = actionId + 1;
      setSubscriptionActionId(newActionId); 
      dispatch(updateSubscriptionNotificationLevel({ actionId: newActionId, channelId: subscription.channelId, subscriptionId: subscription.subscriptionId, notificationLevel }));
    }
  }

  return (
    <Stack verticalAlign='space-between' className={classNames.sidebarContainer}>
      {subscription
        ? <SubscriptionNotificationEditComponent
          loading={actionStatus == 'loading'}
          notificationLevel={subscription.notificationLevel}
          onChange={updateNotificationLevel}
          errorCode={(actionSubscriptionId === subscriptionId && actionId === subscriptionActionId) ? actionErrorCode : null}/>
        : <MessageBar messageBarType={MessageBarType.info}>{t('right_bar.notifications.unavailable')}</MessageBar>}
    </Stack>
  )
}

