import { Api } from '@notidar/api'
import { AuthenticationResult } from '@azure/msal-common'
import { msalInstance } from '../auth'

export const scopes = [
  'https://notidarorg.onmicrosoft.com/api/channel.read',
  'https://notidarorg.onmicrosoft.com/api/channel.write',
  'https://notidarorg.onmicrosoft.com/api/subscription.read',
  'https://notidarorg.onmicrosoft.com/api/subscription.write',
]

export const ApiInstance = new Api({
  baseUrl: process.env.REACT_APP_API_URL,
  securityWorker: async () => {
    let authResult: AuthenticationResult
    try {
      authResult = await msalInstance.acquireTokenSilent({ scopes })
      return {
        headers: { Authorization: `Bearer ${authResult.accessToken ?? authResult.idToken}` },
      }
    } catch (error) {
      await msalInstance.logoutRedirect()
    }
  },
})

declare global {
  var NotidarApiClient: Api<unknown>;
}

globalThis.NotidarApiClient = ApiInstance;

export const ApiClient = ApiInstance.api;
