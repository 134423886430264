import { DateTimeField, EnumField, EnumListField, Field, FieldType, MeasurementField, MoneyField, NumberField, Value } from "@notidar/api"
import { StringValueEditor } from "./string"
import { AllFields, AllValues } from "../utils"
import { NumberValueEditor } from "./number"
import { MoneyValueEditor } from "./money"
import { MeasurementValueEditor } from "./measurement"
import { DateTimeValueEditor } from "./datetime"
import { MarkdownValueEditor } from "./markdown"
import { LinkValueEditor } from "./link"
import { ImageFileValueEditor } from "./imageFile"
import { ChannelId } from "../models"
import { EnumValueEditor } from "./enum"

export interface ValueEditorComponentProps {
  channelId: ChannelId,
  field: AllFields,
  value?: AllValues,
  onUpdate: (field: Field, value: Value) => void,
  onValidation: (field: Field, isValid: boolean) => void,
}

export const ValueEditorComponent = ({ field, value, onUpdate, onValidation, channelId}: ValueEditorComponentProps): JSX.Element | null => {
  switch (field.type) {
    case FieldType.String:
      return <StringValueEditor field={field} value={value} onUpdate={onUpdate} onValidation={onValidation}/>
    case FieldType.Number:
      return <NumberValueEditor field={field as NumberField} value={value} onUpdate={onUpdate} onValidation={onValidation}/>
    case FieldType.Money:
      return <MoneyValueEditor field={field as MoneyField} value={value} onUpdate={onUpdate} onValidation={onValidation}/>
    case FieldType.Measurement:
      return <MeasurementValueEditor field={field as MeasurementField} value={value} onUpdate={onUpdate} onValidation={onValidation}/>
    case FieldType.DateTime:
      return <DateTimeValueEditor field={field as DateTimeField} value={value} onUpdate={onUpdate} onValidation={onValidation}/>
    case FieldType.Markdown:
      return <MarkdownValueEditor field={field} value={value} onUpdate={onUpdate} onValidation={onValidation}/>
    case FieldType.Link:
      return <LinkValueEditor field={field} value={value} onUpdate={onUpdate} onValidation={onValidation}/>
    case FieldType.ImageFileList:
      return <ImageFileValueEditor field={field} value={value} onUpdate={onUpdate} onValidation={onValidation} channelId={channelId}/>
    case FieldType.Enum:
    case FieldType.EnumList:
      return <EnumValueEditor field={field as EnumField | EnumListField} value={value} onUpdate={onUpdate} onValidation={onValidation}/>
    default:
      return null
  }
}