import { Card, CardList } from '../../App/Card'
import { mergeStyleSets, TextField, PrimaryButton, Label, ITheme, useTheme } from '@fluentui/react'
import { ApiClient } from '../../../api'
import { useState, useCallback } from 'react'
import { useAppDispatch } from '../../../redux/hooks'
import { addChannel } from '../../../redux/features/userChannelsSlice'
import { useNavigate } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { ErrorCode, ErrorCodes, getErrorMessageCode } from '@notidar/api'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
    header: {
      margin: '10px 0 0',
    },
    button: {
      margin: '10px 0 0',
    },
    error: {
      color: theme.semanticColors.errorText,
    }
  })
}

export const CreateChannelView = () => {
  const theme = useTheme();
  const classNames = getClassNames(theme);
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [state, setState] = useState({
    name: '',
    isNameValid: false,
    title: '',
    isTitleValid: false,
    description: '',
    isDescriptionValid: true,
    isCreating: false,
    errorCode: null as (ErrorCode | null),
  })

  const validateChannelName = async (value: string): Promise<string> => {
    value = value.trim()
    if (value.length < 5) {
      return t('pages.create_channel.name_too_short', { limit: 5 })
    }

    if (value.length > 32) {
      return t('pages.create_channel.name_too_long', { limit: 32 })
    }

    const regexp = new RegExp('^[a-z0-9]{1}[a-z0-9_.]{3,}[a-z0-9]{1}$')
    if (!regexp.test(value)) {
      return t('pages.create_channel.name_invalid_format')
    }

    try {
      const response = await ApiClient.checkChannelNameAsync({ name: value })
      if (response?.status !== 200) {
        return t('pages.create_channel.name_already_taken', { value })
      }
    } catch (e) {
      return t('pages.create_channel.name_already_taken', { value })
    }

    setState(state => ({ ...state, isNameValid: true }))
    return ''
  }

  const onChannelNameChange = useCallback(
    (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setState(state => ({ ...state, name: newValue || '', isNameValid: false }))
    },
    [],
  )

  const validateChannelTitle = (value: string): string => {
    if (value.length < 4) {
      return t('pages.create_channel.title_too_short', { limit: 4 })
    }

    if (value.length > 120) {
      return t('pages.create_channel.title_too_long', { limit: 120 })
    }

    setState(state => ({ ...state, isTitleValid: true }))
    return ''
  }
  const onChannelTitleChange = useCallback(
    (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setState(state => ({ ...state, title: newValue || '', isTitleValid: false }))
    },
    [],
  )

  const validateChannelDescription = (value: string): string => {
    if (value.length > 420) {
      return t('pages.create_channel.description_too_long', { limit: 420 })
    }

    setState(state => ({ ...state, isDescriptionValid: true }))
    return ''
  }
  const onChannelDescriptionChange = useCallback(
    (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setState(state => ({ ...state, description: newValue || '', isDescriptionValid: false }))
    },
    [],
  )

  const onChannelCreate = async (): Promise<void> => {
    setState(state => ({ ...state, isCreating: true, errorCode: null }))
    try {
      const response = await ApiClient.createChannelAsync({
        name: state.name,
        title: state.title,
        description: state.description,
      })
      dispatch(addChannel(response.data.channel))
      navigate(`/manage/channel/${response.data.channel.name}`)
    } catch (e: unknown) {
      setState(state => ({ ...state, isCreating: false, errorCode: getErrorMessageCode(e) }))
    }
  }

  return (
    <CardList>
      <Card>
        <div className={classNames.container}>
          <h2 className={classNames.header}>{t('pages.create_channel.title')}</h2>
          <TextField
            value={state.name}
            label={t('pages.create_channel.name_label')}
            prefix='@'
            onGetErrorMessage={validateChannelName}
            onChange={onChannelNameChange}
            validateOnLoad={false}
            deferredValidationTime={500}
            required
            placeholder={t('pages.create_channel.name_placeholder')}
          />
          <TextField
            value={state.title}
            label={t('pages.create_channel.title_label')}
            onGetErrorMessage={validateChannelTitle}
            onChange={onChannelTitleChange}
            validateOnLoad={false}
            deferredValidationTime={500}
            required
            placeholder={t('pages.create_channel.title_placeholder')}
          />
          <TextField
            value={state.description}
            label={t('pages.create_channel.description_label')}
            multiline
            autoAdjustHeight
            onGetErrorMessage={validateChannelDescription}
            onChange={onChannelDescriptionChange}
            validateOnLoad={false}
            deferredValidationTime={500}
            placeholder={t('pages.create_channel.description_placeholder')}
          />
          <PrimaryButton
            className={classNames.button}
            disabled={!state.isNameValid || !state.isDescriptionValid || !state.isTitleValid || state.isCreating}
            onClick={onChannelCreate}
            text={t('pages.create_channel.button')}
          />
          {state.errorCode === ErrorCodes.CHANNEL_LIMIT_REACHED ? <Label className={classNames.error}>{t('shared.errors.CHANNEL_LIMIT_REACHED')}</Label> : null}
          {state.errorCode === ErrorCodes.UNKNOWN ? <Label className={classNames.error}>{t('shared.errors.UNKNOWN')}</Label> : null}
        </div>
      </Card>
    </CardList>
  )
}
