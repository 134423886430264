import { Label, PrimaryButton, Stack, TextField } from '@fluentui/react'
import { EnumOption } from '@notidar/api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface CreateEnumOptionFormProps {
  existingEnumOption: EnumOption[]
  onAdd: (enumOption: EnumOption) => void
}

export const CreateEnumOptionForm = ({ existingEnumOption, onAdd }: CreateEnumOptionFormProps) => {
  const { t } = useTranslation();
  const [enumOption, setEnumOption] = useState<Partial<EnumOption>>({});
  const [validationState, setValidationState] = useState<{
    isIdValid: boolean
    isDisplayNameValid: boolean
  }>({ isDisplayNameValid: false, isIdValid: false })

  const validateEnumId = (value: string): string => {
    if (value.length === 0) {
      return ''
    }

    if (value.length < 2) {
      return t('content.fields.enum.id_too_short');
    }

    if (value.length > 32) {
      return t('content.fields.enum.id_too_long');
    }

    const regexp = new RegExp('^[a-z0-9]+[a-z0-9_]*[a-z0-9]+$')
    if (!regexp.test(value)) {
      return t('content.fields.enum.id_invalid_format');
    }

    if (existingEnumOption.some(x => x.enumId === value)) {
      return t('content.fields.enum.id_exists');
    }

    setValidationState(x => ({ ...x, isIdValid: true }))
    return ''
  }

  const enumIdChanged = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setValidationState(x => ({ ...x, isIdValid: false }))
    setEnumOption(x => ({ ...x, enumId: newValue }))
  }

  const validateDisplayName = (value: string): string => {
    if (value.length === 0) {
      return ''
    }

    if (value.length > 32) {
      return t('content.fields.enum.name_too_long')
    }

    setValidationState(x => ({ ...x, isDisplayNameValid: true }))
    return ''
  }

  const displayValueChanged = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setValidationState(x => ({ ...x, isDisplayNameValid: false }))
    setEnumOption(x => ({ ...x, displayString: newValue }))
  }

  const onClick = (): void => {
    setValidationState({ isDisplayNameValid: false, isIdValid: false })
    setEnumOption({})
    onAdd(enumOption as EnumOption)
  }

  return (
    <Stack>
      <Label>{t('content.fields.enum.add_option')}</Label>
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack wrap tokens={{ childrenGap: 10 }} horizontalAlign={'stretch'}>
          <Stack.Item>
            <TextField
              label={t('content.fields.enum.name_label')}
              value={enumOption.displayString ?? ''}
              placeholder={t('content.fields.enum.name_placeholder')}
              validateOnLoad={false}
              onChange={displayValueChanged}
              deferredValidationTime={500}
              onGetErrorMessage={validateDisplayName}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={t('content.fields.enum.id_label')}
              value={enumOption.enumId ?? ''}
              placeholder={t('content.fields.enum.id_placeholder')}
              validateOnLoad={false}
              onChange={enumIdChanged}
              deferredValidationTime={500}
              onGetErrorMessage={validateEnumId}
            />
          </Stack.Item>
        </Stack>
        <Stack wrap tokens={{ childrenGap: 10 }} horizontalAlign={'stretch'}>
          <Stack.Item>
            <PrimaryButton
              disabled={!validationState.isDisplayNameValid || !validationState.isIdValid}
              onClick={onClick}
              text={t('content.fields.enum.add')}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </Stack>
  )
}
