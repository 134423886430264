import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ApiClient } from '../../api'
import type { RootState } from '../store'
import { OperationError, OperationStatus } from '../models'
import { CurrencyCode, LanguageCode, MeasurementSettings, PublisherAccountType, ReaderAccountType, Theme, User, UserStatus } from '@notidar/api'
import { msalInstance } from '../../auth'
import { CacheLookupPolicy } from '@azure/msal-browser'
import { scopes } from '../../api/ApiClient'
import i18n from '../../localization/i18n'

interface UserState {
  status: OperationStatus
  error: OperationError
  user?: User
  readerAccountType?: ReaderAccountType
  publisherAccountType?: PublisherAccountType
}

const initialState: UserState = {
  status: 'idle',
  user: undefined,
  error: null,
  readerAccountType: undefined,
  publisherAccountType: undefined,
}

const getAccountType = (): { readerAccountType: ReaderAccountType; publisherAccountType: PublisherAccountType } => {
  const account = msalInstance.getActiveAccount()
  if (account === null || account.idTokenClaims === undefined) {
    throw new Error('User is not authenticated')
  }
  const readerAccountType =
    (account.idTokenClaims['extension_ReaderAccountType'] as ReaderAccountType | undefined) ?? ReaderAccountType.Basic
  const publisherAccountType =
    (account.idTokenClaims['extension_PublisherAccountType'] as PublisherAccountType | undefined) ??
    PublisherAccountType.Basic

  return { readerAccountType, publisherAccountType }
}

export const getUser = createAsyncThunk('user/getUser', async (_, thunkAPI) => {
  const response = await ApiClient.getUserAsync({ signal: thunkAPI.signal })
  const user = response.data.user

  var { publisherAccountType, readerAccountType } = getAccountType()

  if (user.status === UserStatus.Ready) {
    if (readerAccountType !== user.readerAccountType || publisherAccountType !== user.publisherAccountType) {
      await msalInstance.acquireTokenSilent({
        scopes,
        forceRefresh: true,
        cacheLookupPolicy: CacheLookupPolicy.Skip,
      })

      const refreshedData = getAccountType()
      publisherAccountType = refreshedData.publisherAccountType
      readerAccountType = refreshedData.readerAccountType
    }
  }

  return {
    user,
    readerAccountType,
    publisherAccountType,
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateSettingsTheme: (state, action: PayloadAction<Theme>) => {
      if (state.user?.settings.theme) {
        state.user.settings.theme = action.payload
      }
    },
    updateSettingsLanguage: (state, action: PayloadAction<LanguageCode>) => {
      if (state.user?.settings.language) {
        i18n.changeLanguage(action.payload.toLowerCase())
        state.user.settings.language = action.payload
      }
    },
    updateSettingsCurrency: (state, action: PayloadAction<CurrencyCode | undefined>) => {
      if (state.user) {
        state.user.settings.currencyCode = action.payload;
      }
    },
    updateSettingsMeasurement: (state, action: PayloadAction<MeasurementSettings | undefined>) => {
      if (state.user) {
        state.user.settings.measurements = action.payload;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUser.pending, state => {
        if (state.user === undefined) {
          // refresh case
          state.status = 'loading'
        }
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.user = action.payload.user
        state.publisherAccountType = action.payload.publisherAccountType
        state.readerAccountType = action.payload.readerAccountType
        const expectedLanguage = action.payload.user.settings.language.toLowerCase()
        if (expectedLanguage !== i18n.language) {
          i18n.changeLanguage(expectedLanguage)
        }
      })
      .addCase(getUser.rejected, state => {
        state.status = 'failed'
        state.error = 'Something failed'
      })
  },
})

export const { updateSettingsTheme, updateSettingsLanguage, updateSettingsCurrency, updateSettingsMeasurement } = userSlice.actions

export const selectUserState = (state: RootState) => state.user

export default userSlice.reducer
