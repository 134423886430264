import { Separator, Stack } from '@fluentui/react'
import { ActionsPostSection, ActionsPostSectionItem, PostSection, PostSectionType } from '@notidar/api'
import { SectionEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { ActionItemList } from './ActionItemList'
import { CreateActionItemForm } from './CreateActionItemForm'

const isValid = (section: Partial<ActionsPostSection> & PostSection): boolean => {
  return (
    section.actionItems !== undefined && section.actionItems.length > 0 && !section.actionItems.some(x => !x.displayString || !x.actionField)
  )
}

export interface ActionsSectionEditorComponentProps
  extends SectionEditorComponentProps<PostSectionType.Actions> { }

export const ActionsSectionEditorComponent = ({
  fields,
  hidden,
  onUpdate,
  section,
}: ActionsSectionEditorComponentProps) => {
  const [sectionState, setSectionState] = useState<Partial<ActionsPostSection> & PostSection>(section)

  useEffect(() => {
    onUpdate(sectionState, isValid(sectionState))
  }, [sectionState]) // eslint-disable-line react-hooks/exhaustive-deps

  const removeAction = (index: number): void => {
    if (index !== undefined && index !== -1 && sectionState.actionItems !== undefined) {
      const newActionItems: ActionsPostSectionItem[] = [...sectionState.actionItems]
      newActionItems.splice(index, 1)
      const newState: Partial<ActionsPostSection> & PostSection = { ...sectionState, actionItems: newActionItems }
      setSectionState(newState)
      onUpdate(newState, isValid(newState))
    }
  }

  const addAction = (item: ActionsPostSectionItem): void => {
    const newState: Partial<ActionsPostSection> & PostSection = {
      ...sectionState,
      actionItems: [...sectionState.actionItems ?? [], item],
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  if (hidden) {
    return null
  }

  return (
    <Stack>
      <Stack.Item>
        <ActionItemList items={sectionState.actionItems ?? []} onRemove={index => removeAction(index)} />
      </Stack.Item>
      <Stack.Item>
        <Separator />
        <CreateActionItemForm fields={fields} onAdd={addAction} />
      </Stack.Item>
    </Stack>
  )
}
