import { useExternalScripts } from '../../../hooks'
import { mergeStyleSets } from '@fluentui/react'
import i18n from '../../../localization/i18n'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column',
    },
  })
}

export const SupportView = () => {
  const classNames = getClassNames()
  useExternalScripts('https://js.stripe.com/v3/buy-button.js');

  let buttonId = "buy_btn_1NX8C0ItNZRM8Z4PKq6twext";
  
  const locale = i18n.language;
  if (locale === 'pl') {
    buttonId = "buy_btn_1QDBVsItNZRM8Z4PHXGJ5izQ"
  } else if (locale === 'ru') {
    buttonId = "buy_btn_1QDBbMItNZRM8Z4P7YMZEdAV";
  }

  let supportHtml = `
    <stripe-buy-button
      buy-button-id="${buttonId}"
      publishable-key="pk_live_51NLtRuItNZRM8Z4Pn9zukOChtGq8Bojhyk5u353kmuIKrCpyejL6pux9tMNPVTQP9jkc1CyufA7S4J86IMVs1RQe00rhIKls0s">
    </stripe-buy-button>
  `

  return <div className={classNames.container} dangerouslySetInnerHTML={{ __html: supportHtml }} />
}
