import { SpinButton, Stack } from '@fluentui/react'
import { FieldType } from '@notidar/api'
import { ValueEditorProps } from '../Shared.types'
import { ItemHeader } from '../shared/ItemHeader'
import { useState } from 'react'
import { unitDataMap } from './MeasurementValueComponent'
import { toMeasurementNumber, toMeasurementString } from './utils'

export interface MeasurementValueEditorProps extends ValueEditorProps<FieldType.Measurement> {}

const validate = (value: string, unit: string, subUnitDivider?: number, subUnit?: string, assumeLeftPart?: boolean): string | void => {
  const int = toMeasurementNumber(value, subUnitDivider, assumeLeftPart);
  return toMeasurementString(int, unit, subUnitDivider, subUnit, true) ?? '';
}

export const MeasurementValueEditor = ({ field, value, onUpdate, onValidation }: MeasurementValueEditorProps) => {
  const { symbol, subunitDivider, subunitSymbol, assumeLeftPart } = unitDataMap[field.unit]
  const [newValue, setNewValue] = useState<number | undefined>(value?.measurementPayload?.value ?? undefined)

  const onValueChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
    const result = toMeasurementNumber(value, subunitDivider, assumeLeftPart);
    setNewValue(result)
    onUpdate(field, { type: FieldType.Measurement, measurementPayload: { value: result } })
    onValidation(field, true)
  }

  const onIncrement = (
    value: string,
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ): string | void => {
    return toMeasurementString((newValue ?? 0) + 1, symbol, subunitDivider, subunitSymbol, true);
  }

  const onDecrement = (
    value: string,
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ): string | void => {
    return toMeasurementString((newValue ?? 0) - 1, symbol, subunitDivider, subunitSymbol, true);
  }

  return (
    <Stack>
      <ItemHeader name={field.name} title={field.displayName} />
      <Stack>
        <SpinButton
          value={toMeasurementString(newValue, symbol, subunitDivider, subunitSymbol) || ''}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          onChange={onValueChange}
          onValidate={value => validate(value, symbol, subunitDivider, subunitSymbol, assumeLeftPart)}
        />
      </Stack>
    </Stack>
  )
}
