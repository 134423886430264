import { Dropdown, IDropdownOption, IStackTokens, Separator, Stack, StackItem, TextField } from '@fluentui/react'
import {
  PostSection,
  PostSectionType,
  PropertiesPostSection,
  PropertiesPostSectionItem,
  PropertiesPostSectionType,
} from '@notidar/api'
import { useEffect, useMemo, useState } from 'react'
import { PropertyList } from './PropertyList'
import { SectionEditorComponentProps } from '../../Shared.types'
import { CreatePropertyItemForm } from './CreatePropertyItemForm'
import { useTranslation } from 'react-i18next'

const stackTokens: IStackTokens = { childrenGap: 10 }

const allowedTypes = [
  { key: PropertiesPostSectionType.Icons, text: 'Icon based', translationKey: 'content.sections.properties.display_types.icon_based' },
  { key: PropertiesPostSectionType.Names, text: 'Name based', translationKey: 'content.sections.properties.display_types.name_based' },
]

export const useLocalizedIcons = () => {
  const { t } = useTranslation();
  const localizedTypes = useMemo(() => allowedTypes.map(({ key, text, translationKey }) => ({ key, text: t(translationKey) ?? text })), [t]);
  return localizedTypes;
}

const isValid = (section: Partial<PropertiesPostSection> & PostSection): boolean => {
  return section.items !== undefined && section.items.length > 0
}

export interface PropertiesSectionEditorProps extends SectionEditorComponentProps<PostSectionType.Properties> { }

export const PropertiesSectionEditor = ({ fields, hidden, onUpdate, section }: PropertiesSectionEditorProps) => {
  const { t } = useTranslation();
  const localizedAllowedTypes = useLocalizedIcons();
  const [sectionState, setSectionState] = useState<Partial<PropertiesPostSection> & PostSection>(section)

  useEffect(() => {
    onUpdate(sectionState, isValid(sectionState))
  }, [sectionState]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTitleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    const newState: Partial<PropertiesPostSection> & PostSection = {
      ...sectionState,
      titleString: newValue ?? "",
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const removeAction = (index: number): void => {
    if (index !== undefined && index !== -1 && sectionState.items !== undefined) {
      const newItems = [...sectionState.items]
      newItems.splice(index, 1)
      const newState: Partial<PropertiesPostSection> & PostSection = { ...sectionState, items: newItems }
      setSectionState(newState)
      onUpdate(newState, isValid(newState))
    }
  }

  const addProperty = (item: PropertiesPostSectionItem): void => {
    const newState: Partial<PropertiesPostSection> & PostSection = {
      ...sectionState,
      items: [...sectionState.items ?? [], item],
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onChangeType = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    const newState: Partial<PropertiesPostSection> & PostSection = {
      ...sectionState,
      displayType: option?.key as PropertiesPostSectionType,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  if (hidden) {
    return null
  }

  return (
    <Stack>
      <Stack.Item>
        <Dropdown
          onChange={onChangeType}
          selectedKey={sectionState.displayType}
          placeholder={t('content.sections.properties.display_type_placeholder')}
          options={localizedAllowedTypes}
          label={t('content.sections.properties.display_types_name')}
        />
      </Stack.Item>
      <Stack.Item>
        <TextField
          value={sectionState.titleString ?? ''}
          placeholder={t('content.sections.properties.title_placeholder')}
          validateOnLoad={false}
          onChange={onTitleChange}
          deferredValidationTime={500}
          label={t('content.sections.properties.title')}
        />
      </Stack.Item>
      <Stack.Item>
        <PropertyList items={sectionState.items ?? []} onRemove={index => removeAction(index)} fields={fields} />
      </Stack.Item>
      <Stack.Item>
        <Separator/>
        <CreatePropertyItemForm displayType={sectionState.displayType ?? PropertiesPostSectionType.Names} fields={fields} onAdd={addProperty} />
      </Stack.Item>
    </Stack>
  )
}
